import PremiumContent from "./PremiumContent.jsx";
import "./Premium.scss";

function Premium () {
    return (
        <div className="premium-main">
            <PremiumContent />
        </div>
    )
}

export default Premium;