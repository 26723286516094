import EditableInput from "../../UI/EditableInput/EditableInput.jsx";
import React, {useContext, useEffect, useState} from "react";
import {getDateString, getDateStringFromPlain} from "../../../assets/utility.js";
import {SubscriptionContext} from "../../../context/SubscriptionContext.js";
import "./ProfileBaseInfo.scss";
import {Tooltip} from "primereact/tooltip";
import {fetchUserSubscription} from "../../../fetchingAPI/subscriptions.js";
import { FaCity, FaFlag } from "react-icons/fa";
import { MdSchedule } from "react-icons/md";

function ProfileBaseInfo ({user, info, owner, handleChange}) {

    const {subscription, setSubscription} = useContext(SubscriptionContext);
    const [userSubscription, setUserSubscription] = useState(null);

    const getUserSubscription = async() => {
        const result = await fetchUserSubscription(user.id);
        /*console.log('fetchUserSub: ' + JSON.stringify(result));*/
        setUserSubscription(result.subscription);
    }

    useEffect(()=> {
        /*console.log('ProfileBaseInfo mySub: ' + JSON.stringify(subscription));
        console.log('ProfileBaseInfo age: ' + JSON.stringify(info.age));*/
        if (!owner) {
            getUserSubscription();
        }
    }, []);

    return (
        <div className="profile__header-left">

            <div className="profile__city">
                {/*<span className="profile__city-icon"></span>*/}
                <FaCity />
                <span className="profile__city-info">
                    <span>Город:</span>
                {owner ? <EditableInput
                    name='city'
                    placeholder='Укажите город'
                    onChange={handleChange}
                    info={info}
                /> : info.city !== '' ? info.city : 'Не указано'}
                </span>
            </div>
            <div className="profile__country">
                {/*<span className="profile__country-icon"></span>*/}
                <FaFlag />
                <span className="profile__country-info">
                    <span>Страна:</span>
                    {owner ? <EditableInput
                        name='country'
                        placeholder='Укажите страну'
                        onChange={handleChange}
                        info={info}
                    /> : info.country !== '' ? info.country : 'Не указано'}
                </span>
            </div>
            <div className="profile__last_active">
                {/*<span className="profile__last_active-icon"></span>*/}
                <MdSchedule />
                {getDateString(user)}
            </div>
        </div>
    )
}

export default ProfileBaseInfo;
