import "./PaymentSuccess.scss";
import {Button} from "primereact/button";

function PaymentSuccess () {
    return (
        <div className="profile__row">
            <div className="payment-success__wrapper">
                <div className="payment-success__image">
                    <img src="/storage/reactions/payment-success.png"/>
                </div>
                <h4>Спасибо за покупку!</h4>
                <div className="payment-cancel__button-wrapper">
                    <Button type="button" label="В профиль" severity="warning"
                            onClick={() => {
                                navigate('/homepage/me')
                            }} raised></Button>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess;
