import axios from "axios";
import {APPLICATION_JSON_HEADER} from "../assets/headers.js";

/**
 * Подписка на публичный канал
 * @param channelName
 * @param eventName
 * @param listenCallback
 */
export const subscribeToPublicChannel = (channelName, eventName, listenCallback) => {
    Echo.channel(channelName)
        .subscribed(() => {
            console.log('// Do stuff when public channel is attached ');
        })
        .listenToAll(() => {
            console.log('// Do stuff when any event is received');
        })
        .listen(eventName, listenCallback);
}


/**
 * Подписка на приватный канал
 * @param channelName
 * @param eventName
 * @param listenCallback
 */

export const subscribeToPrivateChannel = (channelName, eventName, listenCallback) => {
    console.log('Sub to private channel ' + channelName);
    Echo.private(channelName)
        .subscribed(() => {
            console.log('//Private channel ' + channelName + ' is attached');
        })
        .listenToAll(() => {
            console.log('// Event received on private channel ' + channelName);
        })
        .listen(eventName, listenCallback);
}

/**
 * Подписка на канал присутствия
 * @param channelName
 * @param eventName
 * @param attachedCallback
 * @param joinCallback
 * @param leaveCallback
 * @param listenCallback
 */

export const subscribeToPresenceChannel = (channelName, eventName, attachedCallback, joinCallback, leaveCallback, listenCallback) => {
    console.log('Sub to presence channel ' + channelName);
    Echo.join(channelName)
        .here((users) => {
            console.log('// Presence channel ' + channelName + ' is attached ');
            attachedCallback(users);
        })
        .joining((user) => {
            console.log('//User ' + user.name + ' joins a presence channel');
            joinCallback(user);
        })
        .leaving((user) => {
            console.log('//User ' + user.name + ' leaves a presence channel');
            leaveCallback(user);
        })
        .error((error) => {
            console.error('Error on presence channel ' + JSON.stringify(error));
        })
        .listen(eventName, listenCallback)
}



/**
 * Отписка от канала
 * @param channelName
 */

export const leaveChannel = (channelName) => {
    console.log('Leaving channel ' + channelName);
    Echo.leave(channelName);
}


/**
 * Отправка сообщения
 * @param channelName
 * @param message
 * @param companionId
 * @param isOnline
 * @param callback
 * @param quoteId
 * @param sticker
 * @param file
 * @returns {Promise<boolean>}
 */

export const sendMessage = async (channelName, companionId, message, isOnline, callback,
                                  quoteId = null, sticker = null, file = null) => {
    console.log('sendMessage: ' + message);
    const broadcastUrl = window.location.origin + "/api/private-event";
    try {
        const response = await axios({
            method: 'post',
            url: broadcastUrl,
            headers: APPLICATION_JSON_HEADER,
            data:  {
                channelName: channelName,
                message: message,
                quoteId: quoteId,
                companionId: companionId,
                isOnline: isOnline,
                sticker: sticker,
                file: file,
            }
        });

        const { data } = response;
        console.log(response);
        if (!isOnline) {
            callback(channelName);
        }
        //return data.status === Status.SUCCESS;
    } catch(e) {
        console.log(e);
        return false;
    }
}

export const sendNotify = async (channelName, notificationType, notificationId, notificationData = '') => {
    const broadcastUrl = window.location.origin + "/api/notify-event";
    try {
        const response = await axios({
            method: 'post',
            url: broadcastUrl,
            headers: APPLICATION_JSON_HEADER,
            data:  {
                channelName: channelName,
                notificationType: notificationType,
                notificationId: notificationId,
                notificationData: notificationData,
            }
        });

        const { data } = response;
        console.log(response);
    } catch(e) {
        console.log(e);
        return false;
    }
}

export const sendPresenceMessage = async (channelName, serviceEvent) => {
    console.log('sendPresenceMessage serviceEvent: ' + serviceEvent);
    const broadcastPresenceUrl = window.location.origin + "/api/presence-event";
    try {
        const response = await axios({
            method: 'post',
            url: broadcastPresenceUrl,
            headers: APPLICATION_JSON_HEADER,
            data:  {
                channelName: channelName,
                serviceEvent: serviceEvent,
            }
        });

        const { data } = response;
        console.log(response);
        //return data.status === Status.SUCCESS;
    } catch(e) {
        console.log(e);
        return false;
    }
}

export const getLocalHistory = async(channelName) => {
    let result = {};
    try {
        const response = await axios({
            method: 'get',
            url: '/channel-messages/' + channelName,
            headers: APPLICATION_JSON_HEADER,
        });

        result = {
            ...response,
            history: response.data.data
        }
    } catch(e) {
        console.log(e);
        return false;
    } finally {
        return result;
    }
}

//export const get






