import './Chat.scss';
import {memo, useCallback, useContext, useEffect, useState} from "react";
import {fetchBatch, fetchUser} from "../../fetchingAPI/users.js";
import {getLocalHistory} from "../../chatAPI/laravel-echo.js";
import {getPresenceMembers, presenceChannel} from "../../chatAPI/ably-client.js";
import ChatTextArea from "./ChatTextArea.jsx";
import {daysBetween, getDateFromString} from "./utils.js";
import {useParams} from "react-router-dom";
import {markMessagesAsRead} from "../../fetchingAPI/messages.js";
import Quote from "./Quote.jsx";
import ChatSendWrapper from "./SendWrapper/ChatSendWrapper.jsx";
import {isFeatureExists, SubscriptionContext} from "../../context/SubscriptionContext.js";
import {BlockUI} from "primereact/blockui";

const getName = (me, companion, div) => {
    return Math.min(me.id, companion.id).toString() + div + Math.max(me.id, companion.id).toString() + div
        + (Math.min(me.id, companion.id) === me.id ? me.name + div + companion.name : companion.name + div + me.name);
}

/**
 * Форматирование данных сообщения
 * @param data
 * @returns {*}
 */

const formatMessagesData = (history, data) => {
    const date = getDateFromString(data.published_date);
    let result = [];
    let message = '';
    let dateFormat = '';
    if (history.length === 0
        || daysBetween( getDateFromString(history.at(-1).published_date), getDateFromString(data.published_date)) > 0) {
        dateFormat = date.toLocaleString('default', {month: 'long'}) + ' ' + date.getDate();
    }
    else {
        dateFormat = '';
    }
    const timeFormat = data.published_time.split(':').slice(0, 2).join(':')
    message =  '\n' +  data.message;
    result.push({id: data.id, date: dateFormat, content: message, time: timeFormat, user:data.user,
        quote: data.quote, sticker: data.sticker, file: data.file});
    return result;
}

/**
 * Проверка сообщения канала присутствия на начало беседы
 * @param data
 */
const checkPresenceDataForTalkInvite = (data) => {
    const dataSplit = data.split('_');
    const authorId = parseInt(dataSplit[0]);
    const receiverId = parseInt(dataSplit[1]);
    const message = dataSplit[2];
    if (dataSplit.length >= 3 && !isNaN(authorId) && !isNaN(receiverId) && message === 'talk') {
        console.log('User ' + receiverId + ' inviting to talk from user ' + authorId);
    }
}

function Chat({ subscribePrivate,
                  messageData,
                  updateChatMessageStamp,
                  me
              }) {

    const { userId } = useParams();

    //const [isPresenceAttached, setIsPresenceAttached] = useState(false);

    //const [me, setMe] = useState(null);
    const [companion, setCompanion] = useState(null);

    const [isCompanionOnline, setIsCompanionOnline] = useState(false);

    const [channelName, setChannelName] = useState();

    // Мемоизация для истории
    const [history, setHistory] = useState([]);

    const [chatContents, setChatContents] = useState({});

    const [message, setMessage] = useState('');

    // Флаг отправки приглашения начала беседы
    const [isTalkInvite, setIsTalkInvite] = useState(false);

    const [quote, setQuote] = useState(null);

    const MemoTextArea = memo(ChatTextArea);

    const {subscription, setSubscription} = useContext(SubscriptionContext);

    const [blocked, setBlocked] = useState(false);


    /**
     * После получения информации о себе, о собеседнике - устанавливается имя канала
     */

    useEffect(() => {
        if (me !== null && me.name !== null && companion !== null && companion.name !==  null) {
            const div = '_';
            const name = getName(me, companion, div);
            //console.log('channelName: ' + name);
            setChannelName(name);
            isOnline();
        }
    }, [me, companion])


    /**
     * После установления стейта названия канала - инициализация содержимого текстового поля,
     * получение истории канала
     */

    useEffect(() => {
        if (channelName !== undefined) {
            const initChatContents = {};
            initChatContents[channelName] = {date: '', time: '', content: '', user: ''};
            setChatContents(initChatContents);
            getChannelLocalHistory(channelName);
            //subscribe();
        }

    }, [channelName]);

    useEffect(() => {
        //console.log('useEffect chatContents: ' + JSON.stringify(chatContents));
    }, [chatContents]);

    /**
     * Получение информации о собеседнике
     * @returns {Promise<void>}
     */
    const getCompanion = async () => {
        const response = await fetchUser({userId});
        if (response.ok) {
            //console.log('companion: ' + JSON.stringify(response.user));
            setCompanion(response.user);
        }
    }


    /**
     * Инициализация - получение информации о себе и собеседнике, получении истории чата.
     * Закрытие канала при выходе
     */

    useEffect(() => {
        console.log('Start Chat Init');
        if (!isFeatureExists(subscription, 'messages-write')) {
            setBlocked(true);
        }
        else {
            //getMe();
            getCompanion();
        }


    }, []);

    /**
     * Инициализация (изменение userId) - получение информации о себе и собеседнике, получении истории чата.
     */

    useEffect(() => {
        console.log('chat companion userId: ' + userId);
        //getMe();
        getCompanion();
    }, [userId]);


    useEffect(() => {
       /* if (companion !== null) {
            const talkInviteEventData =  me?.id + '_' + companion.id + '_talk';
            presenceChannel.update(me?.id,{ data: talkInviteEventData});
            setTimeout(() => {
                setIsTalkInvite(false);
            }, 30000);
        }*/

    }, [companion]);


    const fetchUsersByIds = async (ids) => {
        const result = await fetchBatch(ids, 1);
        //console.log('Chat fetchUsersByIds: ' + JSON.stringify(result));
        result.users.forEach((user) => {
            if (companion.id === user.id) {
                setIsCompanionOnline(true);
            }
        })
    }


    /**
     * Проверка, находится ли собеседник онлайн
     */
    const isOnline = () => {
        console.log('Chat.getPresenceMembers');
        getPresenceMembers(fetchUsersByIds);
    }

    useEffect(() => {
        console.log('isCompanionOnline: ' + isCompanionOnline);

    }, [isCompanionOnline]);


    /** Коллбэк-функции событий канала присутствия - подписка в компоненте Home **/

    /*
    const handleAttachedToPresenceChannel = (users) => {
        console.log('Chat attached to presence');
        setIsPresenceAttached(true);
        console.log('users: ' + JSON.stringify(users));
    }

    useEffect (() => {
        handleAttachedToPresenceChannel(presenceUsers)
    }, [presenceUsers]);

     */


    /*
    const handleJoinUserToPresenceChannel = useCallback((user) => {
        console.log('Chat user join presence: ' + JSON.stringify(user));
        if (user !== undefined && user.data !== undefined)
            checkPresenceDataForTalkInvite(user.data)
    }, [presenceJoinUser]);

    useEffect (() => {
        handleJoinUserToPresenceChannel(presenceJoinUser)
    }, [presenceJoinUser]);

     */

    /*
    const handleLeaveUserFromPresenceChannel = (user) => {
        console.log('Chat user leave presence: ' + JSON.stringify(user));
    }

    useEffect (() => {
        handleLeaveUserFromPresenceChannel(presenceLeaveUser)
    }, [presenceLeaveUser]);


     */


    /**
     * Обработчик изменения содержимого поля ввода сообщения
     * Отправляет сообщение о начале беседы в канал присутсвия - время действия 60 секунд
     * @param evt
     */

    const changeInputHandler = (evt) => {
        evt.preventDefault();
       if (!isTalkInvite) {
            setIsTalkInvite(true);
        }
        //setMessage(evt.target.value);
    }

    useEffect(() => {
        if (!isTalkInvite && me !== null) {
            presenceChannel.leave(me.id);
        }
        else if (isTalkInvite && me !== null && companion !== null) {
            const talkInviteEventData =  me.id + '_' + companion.id + '_talk';
            presenceChannel.update(me.id,{ data: talkInviteEventData});
            setTimeout(() => {
                setIsTalkInvite(false);
            }, 30000);
        }
    }, [isTalkInvite]);


    /**
     * Получение локальной истории канала
     * @param channelName
     * @returns {Promise<void>}
     */

    const getChannelLocalHistory = async (channelName) => {
        updateChatMessageStamp();
        const result = await getLocalHistory(channelName);
        setHistory(result.history);
    }

    useEffect(() => {
        if (history !== undefined) {
            putLocalHistoryToChatContent();
            markAsRead();
        }
    }, [history]);


    /**
     * Отметка сообщений как прочитанные
     */
    const markAsRead = async() => {
        if (history.length > 0) {
            console.log('Mark messages as read history: ' + JSON.stringify(history));
            const lastMessage = history.at(0);
            await markMessagesAsRead({fromId: lastMessage.id, channelName:channelName })
        }
    }


    /**
     * Установка локальной истории в содержимое чата
     */

    const putLocalHistoryToChatContent = () => {
        console.log('putLocalHistoryToChatContent');
        let messages = [];
        let date = null;
        history.forEach((item) => {
            //console.log('putLocalHistory item: ' + JSON.stringify(item));
            let newMessage = '';
            let dateFormat = '';
            if (item.published_time !== undefined) {
                if (date == null || daysBetween(date, getDateFromString(item.published_date))) {
                    date = getDateFromString(item.published_date);
                    dateFormat = date.toLocaleString('default', { month: 'long' }) + ' ' + date.getDate();
                } else {
                    dateFormat = '';
                }
                const timeFormat = item.published_time.split(':').slice(0, 2).join(':');
                newMessage = item.message;
                const user = item.user != null ? item.user.name : '';
                messages.push({id: item.id, date: dateFormat, time: timeFormat,
                    content: newMessage, user: item.user, quote: item.quote !== null ?
                        {content: item.quote.message, time: item.quote.published_time.split(':').slice(0, 2).join(':')} : null,
                sticker: item.sticker, file: item.file});
                //console.log('history messages: ' + JSON.stringify(messages));
                //console.log('history quote: ' + JSON.stringify(item.quote));
            }
        });
        addMessageToChatContent(messages);
    }

    /**
     * Добавить сообщение к выбранному каналу чата
     * @returns {void}
     * @param messages
     */
    const addMessageToChatContent = (messages) => {
        //console.log('addMessage chatContents: ' + JSON.stringify(chatContents));
        console.log('addMessage chatContents keys: ' + JSON.stringify(Object.keys(chatContents)));
        console.log('addMessage channelName: ' + channelName);
        Object.keys(chatContents).forEach((keyName) => {

            if (keyName === channelName) {
                console.log('selected channel: ' + channelName);
                const tmpChatContents = {};
                console.log('chatContents concat: ' + JSON.stringify(chatContents[channelName]));
                console.log('messages concat: ' + JSON.stringify(messages));
                tmpChatContents[channelName] = chatContents[channelName].length > 0 ? chatContents[channelName].concat(messages) : messages;
                setChatContents(tmpChatContents);
            }
        });
    }



    /**
     * Обработка события получения сообщений
     * @param data
     */

    const handleMessageEvent = (data) => {
        console.log('handleMessageEvent data: ' + JSON.stringify(data));
        console.log('handleMessageEvent content before: ' + JSON.stringify(chatContents[channelName]));
        let messages = formatMessagesData(history, data);
        console.log('messages: ' + JSON.stringify(messages))
        addMessageToChatContent(messages);
        //getChannelLocalHistory(channelName);
        //getChannelHistory(client, channelName, 'private');
    }

    useEffect(() => {
        console.log('Chat messageData: ' + JSON.stringify(messageData));
        if (messageData !== undefined && Object.keys(messageData).length > 0) {
            handleMessageEvent(messageData);
        }

    }, [messageData])

    /**
     * Подписка пользователя на канал собеседника
     */
    /*
    const subscribe = () => {

        console.log('Subscribing for user private channel..');
        if (me !== null && channelName !== null) {
            //console.log('getMe companion: ' + JSON.stringify(companion));
            subscribeToPrivateChannel(channelName,  'PrivateMessageEvent', (data) => {
                handleMessageEvent(data);
            });
        }
    }
    */

    const handleQuoteContext = (data) => {
        setQuote(data);
    }

    const handleQuoteReset = () => {
        setQuote(null);
    }


    return (
        <BlockUI blocked={blocked}
                 template={<div className="chat-main__lock">
                     <img className="ribbon-element-text__lock" src="/storage/icons/lock-yellow.png" style={{ fontSize: '3rem' }} />
                     Приобретите премиум, чтобы разблокировать</div>}>
            <div className="chat-main">

                <ChatTextArea
                    key={channelName}
                    chatContents={chatContents[channelName]}
                    me={me}
                    handleQuoteContext={handleQuoteContext}
                />

                <Quote
                    value={ quote !== null && quote.title !== undefined ? quote.title : ''}
                    handleQuoteReset={handleQuoteReset}
                />

                <ChatSendWrapper
                    companion={companion}
                    message={message}
                    quote={quote}
                    channelName={channelName}
                    changeInputHandler={changeInputHandler}
                    getChannelLocalHistory={getChannelLocalHistory}
                    subscribePrivate={subscribePrivate}
                    isCompanionOnline={isCompanionOnline}
                    handleQuoteReset={handleQuoteReset}
                />

            </div>
        </BlockUI>
    )
}

export default Chat;
