import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {useEffect, useState} from "react";
import {fetchPlans} from "../../fetchingAPI/subscriptions.js";
import PremiumCard from "./PremiumCard.jsx";

function PremiumContent () {

    const [plans, setPlans] = useState([]);
    const [currencySymbol, setCurrencySymbol] = useState('');
    const amount = 6;

    const getPlans = async () => {
        const result = await fetchPlans();
        //console.log('plans: ' + JSON.stringify(result));
        setPlans(result.plans.plans);
        setCurrencySymbol(result.plans.currencySymbol);
    }

    useEffect(() => {
        if (plans.length > 0 && currencySymbol.length > 0) {
            console.log('plans: ' + JSON.stringify(plans));
            console.log('currency: ' + JSON.stringify(currencySymbol));
        }
    }, [plans, currencySymbol]);


    useEffect(() => {
        getPlans();
    }, []);

    const header = (duration) => (
        <div className="premium-modal__bronze-header">
            <h1>{duration}</h1>
            <br/>
        </div>
    )


    const title = () => (
        <></>
    )

    const backgrounds = [
        "linear-gradient(0deg, #b7f8db 0%, #50a7c2 100%)",
        "linear-gradient(180deg, #cac531 0%, #f3f9a7 100%)",
        "linear-gradient(0deg, #fceabb 0%, #f8b500 100%)",
        "linear-gradient(90deg, #ff7e5f 0%, #feb47b 100%)",
        "linear-gradient(180deg, #ada996 0%, #f2f2f2 33%, #dbdbdb 66%, #eaeaea 100%)",
    ];

    const localePeriodicity = (days, month)  => {
        return {
            Day: days === 1 || days > 20 && days % 10 === 0
                ? 'день'
                : days >= 2 && days <= 4 || days > 20 && days % 10 >= 2 && days % 10 <= 4
                    ? 'дня' :
                     'дней',
            Month: month === 1 || month > 20 && month % 10 === 0
                ? 'месяц'
                : month >= 2 && month <= 4 || month > 20 && month % 10 >= 2 && month % 10 <= 4
                    ? 'месяца' :
                    'месяцев',
        };
    }


    return (
        <>
            <div className="premium-modal__header-wrapper">
                <img className="premium-modal__image" src="/storage/icons/crown.png"></img>
                <h2 className="mb-2">
                    Получите полный доступ к возможностям сервиса
                </h2>
            </div>

        <div className="premium-modal-container">
            {
                plans.map((plan, index) => {
                    const locPeriodicity =  localePeriodicity(plan.periodicity_type === 'Day' ? plan.periodicity : '',
                        plan.periodicity_type === 'Month' ? plan.periodicity : '');
                    const duration = plan.periodicity + " "
                        + (plan.periodicity_type === 'Day' ? locPeriodicity.Day : locPeriodicity.Month);
                    const price = currencySymbol + " " + plan?.product?.price;
                    if (index > 0 && index < amount)
                        return (
                                <PremiumCard key={Math.random()} title={title} header={header(duration)}
                                             background={backgrounds[index - 1]} price={price} productId={plan?.product?.id}/>
                            )
                    else
                        return (
                            <div key={Math.random()} style={{display: "none"}}></div>
                        )

                })
            }



        </div>
        </>
)
}

export default PremiumContent;
