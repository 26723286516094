import {MultiSelect} from "primereact/multiselect";
import {useEffect, useState} from "react";
import {fetchAreasApi} from "../../fetchingAPI/3-party.js";

function FilterLocation ({city, country, changeFilterHandler}) {

    const [fetchResult, setFetchResult] = useState(null);

    const [countries, setCountries] = useState(null);

    const [cities, setCities] = useState(null);

    const [selectedCountries, setSelectedCountries] = useState(null);

    const [selectedCities, setSelectedCities] = useState(null);

    const fillCountries = (result) => {
        let data = [];
        result.areas.forEach((country) => {
            let item = {
                name: country.name,
            };
            data.push(item);
        });
        setCountries(data);
        console.log('data: ' + JSON.stringify(data));
    }

    const getAreas = async() => {
        const result = await   fetchAreasApi();
        console.log('fetch areas: ' + JSON.stringify(result));
        setFetchResult(result);

        //fillCities(result);

    }

    useEffect(() => {
        //getAreas();
    }, []);

    useEffect(() => {
        if (fetchResult !== null) {
            fillCountries(fetchResult);
        }
    }, [fetchResult]);

    const fillCities = (result, selCountries) => {
        let data = [];
        selCountries.forEach((selCountry) => {
            result.areas.forEach((country) => {
                if (country.name === selCountry.name) {
                    country.areas.forEach((region) => {
                        region.areas.forEach((city) => {
                            let item = {
                                name: city.name
                            };
                            data.push(item);
                        })
                    })
                };
            })
        });
        setCities(data);
    }

    useEffect(() => {
        //console.log('selectedCont: ' + JSON.stringify(selectedCountries));
        if (selectedCountries !== null && selectedCountries.length > 0) {
            fillCities(fetchResult, selectedCountries)
        }
    }, [selectedCountries]);

    return (
        <>
            <div className="filter__city">
                <span className="filter__hint">из: </span>

                <input
                    type="text"
                    name="city"
                    value={city}
                    className="filter__input"
                    onChange={changeFilterHandler}
                    placeholder='Город'
                />
            </div>

            <div className="filter__country">

                {/*
                <MultiSelect value={selectedCountries} onChange={(e) => setSelectedCountries(e.value)} options={countries}
                             optionLabel="name"
                             placeholder="Выберите страну" maxSelectedLabels={3} className="w-full md:w-20rem"/>

                */}
                <input
                    type="text"
                    name="country"
                    value={country}
                    className="filter__input"
                    onChange={changeFilterHandler}
                    placeholder='Страна'
                />
            </div>
        </>
    )
}

export default FilterLocation;