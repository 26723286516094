import "./LikeNotificationModal.scss"
import {Dialog} from "primereact/dialog";
import {SelectButton} from "primereact/selectbutton";
import {Button} from "primereact/button";
import {useEffect, useState} from "react";
import {fetchAddToFavourite} from "../../../fetchingAPI/users.js";

function LikeNotificationModal({
                               visible,
                               user,
                               onDialogHide,
                               setFavouriteCallback,
}) {

    const options = ['public', 'private'];
    const [value, setValue] = useState(options[0]);


    const headerElement = (
        <div className="like-notification_modal__header">
            <img style={{width: "32px", height: "32px"}} src="/storage/icons/like.png"/>
            <span>Вам поставили лайк!</span>
        </div>
    );


    const addToFavourite = async() => {
        console.log('addToFavourite');
        const data = {
            user_id: user?.id,
        }
        const result = await fetchAddToFavourite(data);
    }



    return (

        <Dialog className="like-notification_modal" header={headerElement} visible={visible}
                onHide={() => onDialogHide()}>
            <div className="like-notification_modal__image">
                <img src={user?.photo_url}/>
                <div className="like-notification_modal__info">
                    <p>Вы понравились пользователю {user?.name}</p>
                    <div className="like-notification_modal__buttons">

                        <Button label="" severity="success" onClick={async() => {
                            await addToFavourite();
                            setFavouriteCallback();
                            onDialogHide();
                        }}>
                            <img src="/storage/icons/hearts.png"/>
                            <span>Ответить взаимностью</span>
                        </Button>

                        <Button label="" severity="success" onClick={() => {
                            onDialogHide();
                        }}>
                            <img src="/storage/icons/user-ignore.png"/>
                            <span>Игнорировать</span>
                        </Button>

                    </div>
                </div>

            </div>

        </Dialog>
    )
}

export default LikeNotificationModal;
