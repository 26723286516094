import React, {useContext, useEffect, useState} from "react";
import {fetchBatch, fetchMe, fetchOnline} from "../../fetchingAPI/users.js";
import {fetchLastMessages} from "../../fetchingAPI/messages.js";
import {FavouriteContext} from "../../context/FavouriteContext.js";
import {getPresenceMembers} from "../../chatAPI/ably-client.js";
import "./Messenger.scss";
import {useToastContext} from "../../context/ToastContext.jsx";
import {usePrevious} from "../../hooks/usePrevious.js"
import {isFeatureExists, SubscriptionContext} from "../../context/SubscriptionContext.js";
import MessengerHeader from "./MessengerHeader.jsx";
import MessengerCard from "./MessengerCard.jsx";
import MessengerPremiumCard from "./MessengerPremiumCard.jsx";
import {stripHtml} from "../../assets/utility.js";

function Messenger({
                       me,
                       subscribePrivate,
                       presenceUsers,
                       presenceJoinUser,
                       presenceLeaveUser,
                       presenceMessageData,
                       messageData,
                       profileClickedCallback,
                       // Для обновления последних сообщений у себя осле отправки в компоненте <CharSendWrapper />
                       chatMessageStamp,
}) {

    const [activeTab, setActiveTab] = useState('all');

    const [isPresenceAttached, setIsPresenceAttached] = useState(false);

    const favourite = useContext(FavouriteContext);


    const [online, setOnline] = useState([]);

    const prevOnline = usePrevious(online);

    const [messages, setMessages] = useState([]);

    const [onlineMessages, setOnlineMessages] = useState([]);

    const [favouriteMessages, setFavouriteMessages] = useState([]);

    const lastMessageData = useState(messageData);


    const {subscription, setSubscription} = useContext(SubscriptionContext);

    const [blocked, setBlocked] = useState(false);

    const refreshMessageInterval = 15000;

    const [lastMessage, setLastMessage] = useState(null);



    const getOnlineUsers = async() => {
        const result = await fetchOnline(1);
        //console.log('onlineUsers: ' + JSON.stringify(result));
        setOnline(result.users);
    }

    const fetchUsersByIds = async (ids) => {
        const result = await fetchBatch(ids, 1);
        //console.log('Messenger fetchUsersByIds: ' + JSON.stringify(result));
        setOnline(result.users.filter((user) => user.id !== me?.id))
    }

    const isOnline = (userId) => {
        online.forEach((user) => {
           return user.id === userId;
        });
        return false;
    }

    const getMe = async () => {
        //console.log('Messenger getMe');
        const response = await fetchMe();
        if (response.ok) {
            setMe(response.user);
        }
    }

    const getLastMessages = async () => {
        if (me !== null) {
            const result = await fetchLastMessages(me.id, {online: online, favourite: favourite});
            //console.log('lastMessages: ' + JSON.stringify(result));
            setMessages(result.message.all);
            setOnlineMessages(result.message.online);
            setFavouriteMessages(result.message.favourite);
        }

    }

    useEffect(() => {
        if (me !== null) {
            console.log('fav lastMesages');
            getLastMessages();
        }
    }, [favourite]);

    /** Коллбэк-функции событий канала присутствия - подписка в компоненте Home **/

    const handleAttachedToPresenceChannel = (users) => {
        //console.log('Messenger: Attached To Presence Channel');
        setIsPresenceAttached(true);
        //console.log('Messenger: Attached users: ' + JSON.stringify(users));
    }

    useEffect (() => {
        handleAttachedToPresenceChannel(presenceUsers)
    }, [presenceUsers]);


    /**
     * После присоединения к каналу присутствия - получение информации о себе, получение списка пользователей
     * онлайн, получение последних сообщений
     */
    useEffect( () => {
        //console.log('Messenger isPresenceAttached: ' + isPresenceAttached);
        //getMe();

    }, [isPresenceAttached]);


    useEffect(() => {
        //console.log('me changed: ' + JSON.stringify(me));
        if (me !== null) {
           getPresenceMembers(fetchUsersByIds);
        }
    }, [me])


    useEffect(() => {
        //console.log('online: ' + JSON.stringify(online));
        if (me !== null && online !== prevOnline) {
            //console.log('online lastMesages : ' + JSON.stringify(online));
            getLastMessages();
        }
    }, [online])


    useEffect(() => {
        /*if (me !== null) {
            getLastMessages();
        }*/
    }, [chatMessageStamp]);


    /**
     * После получения сообщения - вывод последних сообщений
     */
    useEffect(() => {
        //console.log('Messenger messageData: ' + JSON.stringify(messageData));
        //console.log('Messenger lastMessageData: ' + JSON.stringify(lastMessageData));
        if (messageData !== undefined && Object.keys(messageData).length > 0) {
            getLastMessages();
        }
    }, [messageData]);


    /**
     * Проверка сообщения канала присутствия на приглашение к беседе
     * @param data
     */
    const checkPresenceDataForTalkInvite = async(data) => {
        const dataSplit = data.split('_');
        const authorId = parseInt(dataSplit[0]);
        const receiverId = parseInt(dataSplit[1]);
        const message = dataSplit[2];
        if (dataSplit.length >= 3 && !isNaN(authorId) && !isNaN(receiverId) && message === 'talk') {
            //console.log('User ' + receiverId + ' inviting to talk from user ' + authorId);
            if (me?.id && me.id === receiverId) {
                //console.log('You are inviting to talk from user ' + authorId);
                return true;
            }
        }
        return false;
    }

    const getChannelName = async(data) => {
        const div = '_';
        const dataSplit = data.split(div);
        const authorId = parseInt(dataSplit[0]);
        const receiverId = parseInt(dataSplit[1]);
        //console.log('authorId: ' + authorId);
        const result = await fetchBatch([authorId], 1);
        //console.log('fetchBatch result: ' + JSON.stringify(result));
        if (result?.users.length > 0) {
           // console.log('users: ' + JSON.stringify(result.users[0].name));
            const companionName = result.users[0].name;
            //console.log('channelName: ' + channelName);
            return Math.min(authorId, receiverId).toString() + div + Math.max(authorId, receiverId).toString() + div
                + (Math.min(authorId, receiverId) === me.id ? me.name + div + companionName : companionName + div + me.name);
        }
        return false;
    }

    /**
     * Обработчик события присоединения пользователя к каналу
     * @param user
     */
    const handleJoinUserToPresenceChannel = async(user) => {
        // console.log('Messenger user join presence: ' + JSON.stringify(user) + ' me: ' + JSON.stringify(me) + ' user: ' + JSON.stringify(user));
        getPresenceMembers(fetchUsersByIds);
        if (user.data !== undefined)
            // Если текущий пользователь приглашен к беседе (автор начал печатать сообщение) - подписка на приватный канал.
            if (await(checkPresenceDataForTalkInvite(user.data))) {
                const channelName = await getChannelName(user.data);
                console.log('Join to private channel ' + channelName);
                subscribePrivate(channelName);
            }
    }

    useEffect (() => {
        handleJoinUserToPresenceChannel(presenceJoinUser)
    }, [presenceJoinUser]);

    const handleLeaveUserFromPresenceChannel = (user) => {
        console.log('Messenger user leave presence: ' + JSON.stringify(user));
    }

    useEffect (() => {
        handleLeaveUserFromPresenceChannel(presenceLeaveUser)
    }, [presenceLeaveUser]);

    /**
     * Обработка события получения сообщений
     * @param data
     */

    const handlePresenceMessageEvent = (data) => {
        console.log('handleMessageEvent data: ' + JSON.stringify(data));
    }



    const [ref, setRef] = useState();
    const toastRef = useToastContext();

    useEffect(() => {
        setRef(toastRef);
    }, [toastRef]);

    /**
     * Показ уведомления
     * @param user
     * @param message
     * @param messageId
     */
    const showToast = (user, message, messageId, publishedTime) => {
        console.log('Show toast Message: ' + JSON.stringify(message));
        ref?.current?.show({
            severity: 'info',
            text: messageId,
            sticky: true,
            content: (<div className="toast-wrapper">
                <img src={user.photo_url}></img>
                <div className="toast-wrapper__text">
                    {user.name + ': ' + stripHtml(message)}
                    <span>{publishedTime.split(':').slice(0, 2).join(':')}</span>
                </div>

            </div>),
            command: () => {console.log('toast clicked');}
        });
    };

    useEffect(() => {
        if (!isFeatureExists(subscription, 'messages-read')) {
            setBlocked(true);
        }
        let refreshMessageTimerId = setInterval(() => getLastMessages(), refreshMessageInterval);
        //getOnlineUsers();
        //subscribe();

        return () => {
            clearInterval(refreshMessageTimerId);
            //leaveChannel(presenceChannelName);
        }
    }, []);


    useEffect(() => {
        if (me !== null) {
            //getLastMessages();
        }
    }, [favourite]);


    /**
     * Показ toast при получении сообщения
     */
    useEffect((() => {
        const lastMessageElem = messages.at(0);
        setLastMessage(lastMessageElem);
        //console.log('Messenger. lastMessage: ' + JSON.stringify(lastMessage));
        if ((lastMessage === null || lastMessage === undefined   || lastMessageElem?.id !== lastMessage.id) && lastMessageElem?.read === 0 && lastMessageElem.user.id !== me.id
                && !lastMessageElem.toast_shown) {
            console.log('Messenger.messages unread');
            showToast(lastMessageElem.user, lastMessageElem.message, "message_" + lastMessageElem.id.toString(), lastMessageElem.published_time);
        }
    }), [messages]);



    return (
        <div className="home-main__messenger">

           <MessengerHeader activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            favouriteMessages={favouriteMessages}
                            onlineMessages={onlineMessages}
                            me={me}
           />

            <MessengerPremiumCard />


            <div className="messenger__userrow">

                {activeTab === 'all' ? (
                    <>
                        {messages !== undefined && messages.map(
                            (message) => ( message.user.id !== me.id ? (
                                <MessengerCard key={message?.id} isOnline={isOnline} message={message}
                                               profileClickedCallback={profileClickedCallback}/>

                            ) : message.receiver.id !== me.id ?
                                <MessengerCard key={message?.id} isOnline={isOnline} message={message}
                                               profileClickedCallback={profileClickedCallback}
                                               receiver={true} />
                               : <React.Fragment key={Math.random()} />))
                        }
                    </>) : activeTab === 'online' ? (
                        <div className="messenger__userrow-wrapper">
                            {onlineMessages.map(
                                (message) => ( message.user.id !== me.id ? (
                                    <MessengerCard key={message?.id} isOnline={isOnline} message={message}
                                                   profileClickedCallback={profileClickedCallback}/>
                                ) : <MessengerCard key={message?.id} isOnline={isOnline} message={message}
                                                   profileClickedCallback={profileClickedCallback}
                                                   receiver={true}/> ))
                            }
                        </div>
                    )
                    : activeTab === 'favourite' ? (
                            <React.Fragment key={Math.random()} >
                            {favouriteMessages?.map(
                                (message) => ( message.user.id !== me.id ? (
                                    <MessengerCard key={message?.id} isOnline={isOnline} message={message} profileClickedCallback={profileClickedCallback}/>
                                ) :  message.receiver.id !== me.id ? (
                                    <MessengerCard key={message?.id} isOnline={isOnline} message={message} profileClickedCallback={profileClickedCallback}
                                                   receiver={true}/>)
                                    : (<React.Fragment key={Math.random()} />)))
                            }
                            </React.Fragment>
                    ) : (<React.Fragment key={Math.random()} />)}

            </div>
            {/* messenger__userrow*/}

            {/* main_messenger */}
        </div>
    )
}

export default Messenger;
