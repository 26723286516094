export const ablyClient = Ably.Realtime.Promise('GOfd4w.WUTceg:YkJpVapvI3bQ-kr9li_Ci9EmLPEll4Z62mm9_XkyWoY');


export class AblyClientChannel {

    /**
     * Имя канала передавать с префиксами public:, private:, presence:
     */
    constructor(name) {
        this.name = name;
    }

    /**
     * Получение истории сообщений
     * @param callback
     */

    getChatHistory(callback) {
        let channel = ablyClient.channels.get(this.name);
        channel.history(callback);
    }

    /**
     * Получение подписчиков канала присутствия
     */

    getPresenceMembers(callback) {
        console.log('ably.client.name: ' + this.name);
        let channel = ablyClient.channels.get(this.name);
        channel.presence.get(callback);
    }

    subscribeEnter(callback) {
        let channel = ablyClient.channels.get(this.name);
        channel.presence.subscribe('enter', callback);
    }

    subscribeUpdate(callback) {
        let channel = ablyClient.channels.get(this.name);
        channel.presence.subscribe('enter', callback);
    }

    enter(data, callback) {
        let channel = ablyClient.channels.get(this.name);
        channel.presence.enter(data, callback);
    }

    update(clientId, data) {
        let channel = ablyClient.channels.get(this.name);
        channel.presence.updateClient(clientId.toString(), data);
    }

    leave(clientId) {
        let channel = ablyClient.channels.get(this.name);
        channel.presence.leaveClient(clientId.toString());
    }

}

export const presenceChannelName = 'Main-Presence';
export const presenceChannel = new AblyClientChannel('presence:' + presenceChannelName);

export const getPresenceMembers = (callback) => {
    let result = [];
    presenceChannel.getPresenceMembers(function(err, members) {
        console.log('channel.presence.get err: ' + err);
        console.log('There are ' + members?.length + ' members on this channel');
        members?.forEach((member) => {
            console.log('member: ' + JSON.stringify(member));
            result.push(member.data.id)
        });

        callback(result);
        //console.log('The first member has client ID: ' + members[0].clientId);
    });
}