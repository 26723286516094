import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {useEffect, useRef, useState} from "react";
import {fetchGifts, fetchUserGifts} from "../../fetchingAPI/gifts.js";
import {Carousel} from "primereact/carousel";
import "./GiftModal.scss"
import {fetchCreatePayment, fetchPaymentCallbackTest} from "../../fetchingAPI/payments.js";
import {fetchCurrenciesRates} from "../../fetchingAPI/3-party.js";
import TinkoffPaymentForm from "../Integrations/PaymentsForms/TinkoffPaymentForm.jsx";

function GiftModal ({visible, onGiftModalHide, user, onGiftBuy}) {

    const [gifts, setGifts] = useState(null);
    const [selectedGift, setSelectedGift] = useState(null);
    const [formData, setFormData] = useState({});
    const submitRef = useRef(null);

    const getGifts = async () => {
        let result = await fetchGifts();
        setGifts(result.gifts)
    }

    useEffect(() => {
        if (gifts !== null) {
            console.log('gifts: ' + JSON.stringify(gifts));
        }
    }, [gifts]);

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const giftTemplate = (gift) => {
        return (
            <div className="gift-modal__template border-1 surface-border border-round m-2 text-center py-2 px-3">
                <div className="gift-modal__price">
                    {gift?.product?.price}
                    <img src="/storage/icons/coin.png"></img>
                </div>
                <div className="mb-3">
                    <img src={gift.url} alt={gift.name} className="w-6 shadow-2 gift-modal__image"
                         onClick={(e) => {handleSelect(e, gift);}}/>
                </div>
                <div>
                    <h4 className="mb-1">{gift.name}</h4>
                    <div className="mt-5 flex flex-wrap gap-2 justify-content-center">

                    </div>
                </div>
            </div>
        );
    };

    const setSelectedGiftStyle = (image) => {
        console.log('setSelectedStyle');
        const container = image.closest('.surface-border');
        const selectedClassName = 'gift-modal__selected';
        if (container.classList.contains(selectedClassName)) {
            console.log('remove');
            container.classList.remove(selectedClassName);
        } else {
            console.log('add');
            container.classList.add(selectedClassName);
        }
    }

    const handleSelect = (e, gift) => {
        const data = {
            gift: gift,
            target: e.target,
        };
        console.log('selectedGift: ' + JSON.stringify(selectedGift?.gift));
        if (selectedGift !== null) {
            // Снятие выделения
            setSelectedGiftStyle(selectedGift.target);
            if (selectedGift.target === e.target) {
                setSelectedGift(null);
            } else {
                setSelectedGift(data);
            }
        // Если выбран впервые
        } else {
            setSelectedGift(data);

        }
    }

    const getUserGifts = async() => {
        let result = await fetchUserGifts();
        //console.log('user gifts result: ' + JSON.stringify(result));
    }

    useEffect(() => {
        if (selectedGift !== null && selectedGift.target !== undefined) {
            console.log('new selected: ' + JSON.stringify(selectedGift.gift));
            setSelectedGiftStyle(selectedGift.target)
        }
    }, [selectedGift]);


    useEffect(() => {
        console.log('formData: ' + JSON.stringify(formData));
        if (Object.keys(formData).length > 0) {
            submitRef.current.click();
        }
    }, [formData]);


    const createPayment = async(gift) => {
        let result = await fetchCreatePayment({productIds: [gift?.product?.id],
            receiverId: user.id});
        console.log('createPayment result: ' + JSON.stringify(result));
        const data = result.payment.form.original.form;

        result = await fetchCurrenciesRates();
        //console.log('getCUrreniesRates result: ' + JSON.stringify(result));
        if (result.ok) {
            data.amount = Number((data.amount / result.rates.USD).toFixed(2));
        }
        data.description = gift?.name;
        setFormData(data);

        //const result = await fetchPaymentCallbackTest();
        console.log('result: ' + JSON.stringify(result));
    }

    const handleGiftBuy = (gift) => {
        console.log('gift buy: ' + JSON.stringify(gift.gift));
        //fetchPaymentCallbackTest();
        createPayment(gift?.gift);

    }


    useEffect(() => {
        getGifts();
        getUserGifts();
    }, []);


    return (
        <Dialog className="gift-modal" header="Выберите подарок, чтобы порадовать собеседника:" visible={visible}
                onHide={() => onGiftModalHide()}>

            <div className="card">
                <Carousel value={gifts} numVisible={3} numScroll={3} responsiveOptions={responsiveOptions}
                          itemTemplate={giftTemplate}/>
            </div>

            <TinkoffPaymentForm formData={formData} ref={submitRef}/>

            <div className="gift-modal__buttons">
                <Button label="Купить" severity="success" onClick={() => {
                    handleGiftBuy(selectedGift)
                }}
                        disabled={selectedGift == null}/>
            </div>

        </Dialog>
    )
}

export default GiftModal;