import {Menu} from "primereact/menu";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {useRef, useState} from "react";
import {fetchLogout} from "../../fetchingAPI/authentication.js";
import AuthenticationModal from "../Modal/AuthenticationModal.jsx";

function ProfileMenu ({setPremiumVisible}) {

    const menuRight = useRef(null);
    const navigate = useNavigate();

    const [authModalVisible, setAuthModalVisible] = useState(false);

    const itemRenderer = (item, options) => (
        <a className="flex align-items-center px-3 py-2 cursor-pointer" onClick={options.onClick}>
            <span className={`${item.icon} text-primary`} />
            <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
            {item.badge && <Badge className="ml-auto" value={item.badge} />}
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
    );

    const handleLogout = async () => {

        const state = await fetchLogout();
        if (state) {
            navigate('/login');
            location.reload();
        }

        console.log('Выйти!');
    }

    const hideAuthModal = () => {
        setAuthModalVisible(false);
    }

    const items = [
        {
            label: '',
            items: [
                /*{
                    label: 'Вход',
                    command: () => {
                        setAuthModalVisible(true);
                    },
                },*/
                {
                    label: 'Моя анкета',
                    command: () => {
                        navigate('/homepage/me');
                        //toast.current.show({ severity: 'success', summary: 'Success', detail: 'File created', life: 3000 });
                    }
                },
                {
                    label: 'Любимые',
                    command: () => {
                        navigate('/homepage/favourite');
                        //toast.current.show({ severity: 'success', summary: 'Success', detail: 'File created', life: 3000 });
                    }
                },
                /*{
                    label: 'Премиум',
                    command: () => {
                        setPremiumVisible();
                    }
                }*/,
                {
                    label: 'Выход',
                    command: () => {
                        handleLogout();
                    }

                }
            ]
        }
    ];


    return (
        <>
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
            <Button label="Профиль" icon="pi pi-align-right" className="mr-2" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
            <AuthenticationModal visible={authModalVisible} onAuthModalHide={hideAuthModal}/>
        </>
    )
}

export default ProfileMenu;