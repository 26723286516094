import "./KassifyPaymentForm.scss";
import {forwardRef} from "react";

const KassifyPaymentForm = forwardRef(
    function KassifyPaymentForm({formData}, submitRef) {

    const url = "https://kassify.pro/sci_v3";
    const ids = import.meta.env.VITE_KASSIFY_MERCHANT_ID;

    return (
        <form method="get" action={url}
              name="checkout_kassify" ref={submitRef}>
            <input type="hidden" name="ids" value={ids}/>
            <input type="hidden" name="summ" value={formData.sum} size="7"/>
            <input type="hidden" name="s" value={formData.hash}/>
            <input type="hidden" name="us_id" value={formData.lid}/>
            <input type="hidden" name="o" value=""/>
            <input type="hidden" name="val" value={formData.val}/>
            <input type="hidden" name="lang" value={formData.lang}/>
            <input type="submit" style={{display: "none"}}/>
        </form>
    )
});

export default KassifyPaymentForm;