import ProfileLookCard from "../Cards/ProfileLookCard.jsx";
import ProfileLifestyleCard from "../Cards/ProfileLifestyleCard.jsx";
import ProfileHobbyCard from "../Cards/ProfileHobbyCard.jsx";
import EditableInput from "../../UI/EditableInput/EditableInput.jsx";
import React, {useEffect} from "react";
import Editable from "../../Editable/Editable.jsx";
import {Card} from "primereact/card";

function MyProfileQuestionnaire ({
                                   user,
                                   info,
                                   prefs,
                                   handleChangeSelect,
                                   handleChange
    }) {

    useEffect(() => {
        //console.log('info: ' + JSON.stringify(info));
    }, [info]);

    const handleInputChange = () => {
        handleChange('spend_time', )
    }

    return (
        <>
            <h5 className="m-0 mb-3">
                Будь открытым и найдешь своего человека
            </h5>
            <div className="profile__row profile__questions-row">

                <ProfileLookCard user={user}
                                 info={info}
                                 prefs={prefs}
                                 handleChangeSelect={handleChangeSelect}
                                 handleChange={handleChange}
                />

                <ProfileLifestyleCard
                    info={info}
                    prefs={prefs}
                    handleChange={handleChange}
                    handleChangeSelect={handleChangeSelect}
                />

                <ProfileHobbyCard
                    info={info}
                    handleChange={handleChange}
                />

                {/*
                <div className="profile__row">
                    <div className="profile__bio">
                        {/* О себе


                    </div>
                </div> */}

            </div>

            {/* About */
            }


        </>
    )
}

export default MyProfileQuestionnaire;