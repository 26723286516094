/**
 * Функция подсчета кол-ва дней между двумя датами
 * @param first
 * @param second
 * @returns {number}
 */
import {Image} from "primereact/image";

export function daysBetween(first, second) {
    // Copy date parts of the timestamps, discarding the time parts.
    let one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
    let two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

    // Do the math.
    let millisecondsPerDay = 1000 * 60 * 60 * 24;
    let millisBetween = two.getTime() - one.getTime();
    let days = millisBetween / millisecondsPerDay;

    // Round down.
    return Math.floor(days);
}

/**
 * Функция получение объекта типа Date из строки
 * @param string
 * @returns {Date}
 */

export function getDateFromString(string) {
    return new Date(string);
}

/**
 * Функция получения времени из строки с датой/временем
 * @returns {string}
 */

export const getTime = () => {
    const timeSplit = new Date(Date.now()).toISOString().split('T').at(-1).split(':');
    return timeSplit.slice(0, 2).join(':');
}

export const downloadURI = (uri, name) => {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const downloadFile = (path) => {
    downloadURI(path, path.split('/').at(0))
}

export const getImageDimensions = (url) => {
    var img = new Image();
    img.onload = function(){
        var height = img.height;
        var width = img.width;
        console.log('height: ' + height);
        console.log('width: ' + width)
        // code here to use the dimensions
        setImageDimensions({height: height, width: width});
    }
    img.src = url;
}

export const copyToClipboard = (value) => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(value);
}