import {AblyProvider} from "ably/react";
import Chat from "./Chat.jsx";
import React, {useEffect, useState} from "react";
import {ablyClient} from "../../chatAPI/ably-client.js";
import ProfileHeader from "../Profile/ProfileHeader.jsx";
import {useParams} from "react-router-dom";
import {fetchUser} from "../../fetchingAPI/users.js";

function ChatProvider({
                          subscribePrivate,
                          messageData,
                          updateChatMessageStamp,
                          me,

                      }) {

    const {userId} = useParams();
    const [user, setUser] = useState(null);

    ablyClient.connection.on('connected', function () {
       console.log('ChatProvider client connected');
       //console.log('clientId: ' +  client.clientId);
    });


    ablyClient.connection.on('failed', function () {
        console.log('ChatProvider client connection failed');
    });



    return (
        <AblyProvider client={ablyClient}>
            <Chat
                subscribePrivate={subscribePrivate}
                updateChatMessageStamp={updateChatMessageStamp}
                messageData={messageData}
                me={me}
            />
        </AblyProvider>
    )
}

export default ChatProvider;