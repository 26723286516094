import StickerModal from "./StickerModal.jsx";
import EmojiPicker from "emoji-picker-react";
import {leaveChannel, sendMessage} from "../../../chatAPI/laravel-echo.js";
import {memo, useCallback, useEffect, useRef, useState} from "react";
import ChatFileUploader from "../ChatFileUploader.jsx";

function ChatSendWrapper ({
                            isCompanionOnline,
                            subscribePrivate,
                            channelName,
                            message,
                            quote,
                            companion,
                            getChannelLocalHistory,
                            changeInputHandler,
                            handleQuoteReset,
                        }) {


    const [attach, setAttach] = useState(null);
    const [sticker, setSticker] = useState(null);

    const stickerModalRef = useRef(null);

    const MemoEmojiPicker = memo(EmojiPicker);
   // const MemoStickerModal = memo(StickerModal);
    const handleEmojiClickRef = useRef();
    const handleEmojiClickFix = useCallback((...args) => handleEmojiClickRef.current(...args), []);

    const emojiPickerRef = useRef(null);

    const [stickerVisible, setStickerVisible] = useState(false);

    const sendButtonRef = useRef(null);

    const inputRef = useRef(null);

    useEffect((emojiData) => {
        handleEmojiClickRef.current = onReactionClick;
        //onReactionClick(emojiData);
    });


    const sendMessageHandler = () => {
        if (isCompanionOnline) {
            subscribePrivate(channelName);
        }
        /*presenceChannel.subscribeUpdate(function (member) {
            console.log(member.data); // => not moving
        })*/
        //sendPresenceMessage(presenceChannelName, '12312');

        const quoteId = quote !== null && quote.id !== undefined ? quote.id : null;
        sendMessage(channelName, companion.id, inputRef.current.value, isCompanionOnline, getChannelLocalHistory, quoteId, sticker, attach);
        handleQuoteReset();
        inputRef.current.value = '';
    }


    const onReactionClick = (emojiData) => {
        console.log('emoji clicked: ' + JSON.stringify(emojiData));
        inputRef.current.value += emojiData.emoji;
        setMessage(inputRef.current.value);
        hideEmojiPicker();
    }

    const handleEmojiClick = () => {
        console.log('emoji.click');
        hideStickerModal();
        const emojiPicker = emojiPickerRef.current;
        //console.log('emoji: ' + emojiPickerRef.current);
        if (emojiPicker.style.display === 'none') {
            emojiPicker.style.display = 'block';
        } else if (emojiPicker.style.display === 'block') {
            emojiPicker.style.display = 'none';
        }
    }

    const hideEmojiPicker = () => {
        const emojiPicker = emojiPickerRef.current;
        emojiPicker.style.display = 'none';
    }

    /**
     * При клике по стикеру - установка в стейт
     * @param stickerData
     */

    const onStickerClick = (stickerData) => {
        console.log('stickerData: ' + stickerData);
        setSticker(stickerData);
    }

    const hideStickerModal = () => {
        setStickerVisible(false);
        const stickerModals = Array.from(document.getElementsByClassName('chat-main__modal-base'));
        stickerModals.forEach((stickerModal) => {
            stickerModal.style.display = 'none';
        })

        /*const profileModals = Array.from(document.getElementsByClassName('profile__mymodal'));
        profileModals.forEach((profile) => {
            profile.click();
        })*/
        //stickerModalRef.current.style.display = 'none';
    }

    /**
     * Обработка нажатия клавиши Enter в поле ввода
     */

    useEffect(() => {

        const chatInput = inputRef.current;
        const sendButton = sendButtonRef.current;
        const handleInputKeyPress = event => {
            // If the user presses the "Enter" key on the keyboard
            if (event.key === "Enter") {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                sendButton.click();
            }
        };
        chatInput.addEventListener('keypress', handleInputKeyPress);
        inputRef.current.focus();

        // returned function will be called on component unmount
        return () => {
            chatInput.removeEventListener('keypress', handleInputKeyPress);
            leaveChannel(channelName);
        }

    }, []);

    useEffect(() => {
        if (inputRef !== null && inputRef !== undefined) {
            inputRef.current.focus();
        }
    }, [companion]);

    /**
     * При установке стикера - отправка сообщения
     */

    useEffect(() => {
        if (sticker !== null) {
            console.log('sticker: ' + sticker + ' message: ' + message);
            sendMessageHandler();
            setSticker(null);
        }
    }, [sticker]);

    /**
     * Обработчик завершения загрузки файла
     */
    const onUpload = (event) => {
        console.log('file uploaded');
        const response = JSON.parse(event.xhr.response);
        //console.log('response: ' + JSON.stringify(response));
        const data = {
            path: response.path,
            mimeType: response.mime_type,
        };
        console.log('data: ' + JSON.stringify(data));
        setAttach(data);
    }

    /**
     * После установки аттачмента - отправка сообщения
     */

    useEffect(() => {
        console.log('useEffect attach: ' + JSON.stringify(attach));
        if (attach !== null) {
            sendMessageHandler();
            setAttach(null);
        }
    }, [attach]);

    useEffect(() => {
        inputRef.current.focus();
    }, [quote]);




    return (
        <>
            <div className="chat-main__send-wrapper">

               <ChatFileUploader onUpload={onUpload}/>

                <input type="text" className="chat-main__input" value={message.text} ref={inputRef} autoFocus
                       onChange={changeInputHandler} placeholder="Напишите сообщение.."></input>


                <div className="chat-main__sticker-wrapper" ref={stickerModalRef}>
                    <StickerModal  onClickCallback={(stickerData) => {
                        onStickerClick(stickerData);
                    }} onShowStickerModal={hideEmojiPicker}
                                  visible={stickerVisible}
                    />
                </div>


                <div className="chat-main__smile"
                     onClick={handleEmojiClick}
                >
                    <svg width="25px" height="25px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                         fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round"
                         strokeLinejoin="miter">
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="8" y1="9" x2="8.01" y2="9" strokeWidth="2" strokeLinecap="round"></line>
                        <line x1="15.99" y1="9" x2="16" y2="9" strokeWidth="2" strokeLinecap="round"></line>
                        <path d="M16.61,14A5,5,0,0,1,14,16.61a5.07,5.07,0,0,1-3.9,0A5,5,0,0,1,7.39,14"></path>
                    </svg>
                </div>

                <button className="chat-main__button"
                        ref={sendButtonRef}
                        onClick={() => {
                            sendMessageHandler();
                        }}
                >Отправить
                </button>
            </div>
            <div ref={emojiPickerRef} style={{display: "none", height: "0"}} >
                <MemoEmojiPicker style={{position: "relative", top: "-30vw", left: "24vw"}}
                             onEmojiClick={(emojiData, event) => {
                                 inputRef.current.focus();
                                 //onReactionClick(emojiData)
                                 handleEmojiClickFix(emojiData);
                             }}

                />
            </div>
        </>
    )
}

export default ChatSendWrapper;
