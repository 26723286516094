import React, {useEffect, useRef, useState} from "react";
import {fetchFavourite, fetchFilter} from "../../fetchingAPI/users";
import { useSelector } from "react-redux";

import './ProfilesList.scss'

import ProfileCard from "../ProfileCard/ProfileCard";

const shuffle = (array) => {
    return array.sort(() => Math.random() - 0.5);
};

function ProfilesList(props) {
    const [ users, setUsers ] = useState([]);

    const [ meta, setMeta ] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const filter = useSelector(state => state.filterReducer);

    const loadMoreRef = useRef();
    
    useEffect(() => {
        loadStartContent();
    }, [filter]);

    const loadMore = () => {
        if (loadMoreRef.current !== undefined) {
            var bodyRect = document.body.getBoundingClientRect(),
                elemRect = loadMoreRef.current?.getBoundingClientRect(),
                offset   = elemRect?.top - bodyRect.top;
            if (window.innerHeight + window.scrollY >= offset) {
                setIsLoading(true);
                handleLoadNextPage(null);
            }

        }

    }

    useEffect(() => {

        return () => {
            //if (props.loadMore !== null || props.loadMore !== undefined || props.loadMore === true
            //    && props.favourite === null || props.favourite === undefined) {
        window.removeEventListener('scroll', loadMore);
            //}

        }
    }, []);

    const loadContent = async (page, users) => {
        if (props.favourite !== null && props.favourite !== undefined) {

            const result = await fetchFavourite();

            setUsers([...users, ...result.message]);

        }
        else {
            const result =  await fetchFilter({ page, filter });
            // console.log(result);
            if (result.ok) {
                //setUsers([...users, ...result.message])
                setIsLoading(false);

                if (props.size !== null && props.size !== undefined) {
                    setUsers([...users, ...shuffle(result.users).slice(0, props.size)]);
                }
                else {
                    setUsers([ ...users, ...result.users ]);
                }

                setMeta(result.meta);
            }
        }


    }

    useEffect(() => {
        const currentPage = meta?.current_page;
        const lastPage = meta?.last_page;
        if (users.length > 0 && currentPage !== lastPage) {
            window.addEventListener('scroll', loadMore);
        }

    }, [users])

    const loadStartContent = async () => {
        loadContent(1, []);
    };


    const addContent = async (nextPage) => {
        loadContent(nextPage, users);
    };

    useEffect(() => {
        loadStartContent();
    }, []);


    useEffect(() => {
        loadStartContent();
    }, [props.favourite]);


    const handleLoadNextPage = (evt) => {
        if (evt !== null)
            evt.preventDefault();

        const currentPage = meta.current_page;
        const lastPage = meta.last_page;

        if (currentPage < lastPage) {
            window.removeEventListener('scroll', loadMore);
            addContent(currentPage + 1);
        }
    };



    if (users.length === 0) {
        return (
            <div className="profiles-list">Пользователи не найдены</div>
        )
    }

    return (
        <div className={"profiles-list"  + (props?.size ? ' profiles-list-bottom' : '')}>
            {props.header !== null && props.header !== undefined ? <h2>Вам могут подойти: </h2> : <></>}
            <div className={"profiles-list__main" + (props?.size ? ' profiles-list__main-bottom' : '')}>
                {users.map(
                    (user) => (
                        <ProfileCard
                            key={user.id}
                            user={user}
                            deactivateChatCallback={props.deactivateChatCallback}
                        />
                    ))
                }
            </div>

            {meta.current_page < meta.last_page && props.loadMore == null || props.loadMore === undefined || props.loadMore === true ?
               ( <div className="profiles-list__control">
                    <button
                        type="button"
                        onClick={handleLoadNextPage}
                        className="profiles-list__btn"
                        ref={loadMoreRef}
                        style={{display: "none"}}
                    >Загрузить еще</button>
                </div>)
                :(
                    <></>
                )
            }

            {
                isLoading ? (
                    <div className="lds-roller lds-post-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                ) : (<React.Fragment key={Math.random()}/>)
            }


        </div>
    )
}

export default ProfilesList;