import {Badge} from "primereact/badge";
import React from "react";

function MessengerHeader ({activeTab, setActiveTab, onlineMessages, favouriteMessages, me}) {

    const setTab = (tab) => {
        switch(tab) {
            case 'all':
                setActiveTab(tab);
                break;
            case 'online':
                setActiveTab(tab);
                break;
            case 'favourite':
                setActiveTab(tab);
                break;
        }
    }

    return (
        <div className="messenger__row messenger__header">

            <div
                className={activeTab === 'all' ? "p-overlay-badge messenger__button-active" : " p-overlay-badge messenger__button"}
                onClick={() => {
                    setTab('all')
                }}>
                Все
                {/*<Badge value={messages.length} severity="success" ></Badge> */}
            </div>
            <div
                className={activeTab === 'online' ? "p-overlay-badge messenger__button-active" : "p-overlay-badge messenger__button"}
                onClick={() => {
                    setTab('online')
                }}>
                Онлайн
                {onlineMessages?.length > 0 ? <Badge value={onlineMessages.length} severity="success"></Badge> : <></>}

            </div>
            <div
                className={activeTab === 'favourite' ? "p-overlay-badge messenger__button-active" : "p-overlay-badge messenger__button"}
                onClick={() => {
                    setTab('favourite')
                }}>
                Любимые
                <Badge
                    value={favouriteMessages?.filter((message) => message.user.id !== me.id || message.receiver.id !== me.id).length}
                    severity="success"></Badge>
            </div>
        </div>
    )
}

export default MessengerHeader;