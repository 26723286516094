import {Card} from "primereact/card";
import React from "react";
import "./SpendTimeCard.scss";

function SpendTimeCard ({info, notFilledMessage}) {


    return (
        <div className="profile__row profile__row-question profile__row-question-spend-time">
            <Card title="Люблю проводить время:" className="profile__spend-time">
                {info.spend_time === "" ? notFilledMessage : info.spend_time}
            </Card>
        </div>
    )
}

export default SpendTimeCard;