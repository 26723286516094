import {Card} from "primereact/card";
import React, {useEffect, useState} from "react";
import {isTagExists} from "../Misc/utils.js";
import "./ProfileBadgeCard.scss";

const badgeData = {
    'Гриффиндор': {
        title: 'Гриффиндор',
        subtitle: 'Может, в Гриффиндор дорога, По ней храбрые идут, Им и доблесть, и отвага В веках славу создают.',
        img: '/storage/badges/griff-badge2.png',
        text: ' Поздравляем! Я — староста Перси Уизли, и я рад приветствовать вас на\n' +
            '                    факультете Гриффиндор. Наш герб — лев, храбрейший из всех живых существ;\n' +
            '                    цвета нашего факультета — алый и золотой, а наша общая комната находится в\n' +
            '                    гриффиндорской башне.'
    },

    'Слизерин': {
        title: 'Слизерин',
        subtitle: 'Слизерин брал таких же, как он, хитрецов,\n' +
            '\n' +
            'Безупречных, к тому же, по крови.',
        img: '/storage/badges/slyth-badge.webp',
        text: 'Поздравляем! Я — староста Джемма Фарли, и я рада приветствовать вас на факультете Слизерина. ' +
            'Наш герб — змея, мудрейшая из существ. Наши цвета — изумрудно-зеленый и серебряный. ' +
            'Вход в нашу гостиную спрятан в подземельях Хогвартса. Как вы увидите, её окна выходят на подземные озера замка. '
    }
}

function ProfileBadgeCard ({user}) {

    const [faculty, setFaculty] = useState(null);

    useEffect(() => {
        const griff = 'Гриффиндор';
        const slyth = 'Слизерин';
       if (isTagExists(user.tags, griff)) {
            setFaculty(griff);
        } else {
            setFaculty(slyth);
        }
    }, []);



    return (
        <div className="col-md-6">
            {
                faculty !== null ? (
                    <div className="profile__badge-card">
                        <Card title={badgeData[faculty].title}
                                               subTitle={badgeData[faculty].subtitle}
                                               className="md:w-25rem">
                            <img className="profile__badge-tab-img" src={badgeData[faculty].img}/>
                            <p className="m-0">
                                {badgeData[faculty].text}
                            </p>
                        </Card>
                    </div>
            ) : null
            }


        </div>
    )
}

export default ProfileBadgeCard;