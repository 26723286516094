import {APPLICATION_JSON_HEADER} from "../assets/headers.js";
import {Status} from "./status.js";

export async function fetchTags() {
    let result = {
        ok: false,
        tags: [],
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/tags',
            headers: APPLICATION_JSON_HEADER,
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            tags: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            tags: e.message,
        }
    }
    finally {
        return result
    }
}