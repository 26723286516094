import React, {useContext} from "react";
import ProfileBaseInfo from "./Header/ProfileBaseInfo.jsx";
import ProfileShare from "./Header/ProfileShare.jsx";
import {Card} from "primereact/card";
import {Chip} from "primereact/chip";
import {Image} from "primereact/image";
import EditableInput from "../UI/EditableInput/EditableInput.jsx";
import {isTagExists} from "./Misc/utils.js";
import {SubscriptionContext} from "../../context/SubscriptionContext.js";
import ProfileActions from "./ProfileActions.jsx";
import {Tooltip} from "primereact/tooltip";
import {getDateStringFromPlain} from "../../assets/utility.js";

function ProfileHeader ({
                            user,
                            info,
                            owner,
                            isChatActive,
                            activateChat,
                            setFavourite,
                            inFavourite,
                            meInFavourite,
                            handleChange,
                            isLogIn,
    }) {

    const {subscription, setSubscription} = useContext(SubscriptionContext);

    const storageUrl = '/storage';
    const badgeUrl = isTagExists(user.tags, 'Гриффиндор') ? '/badges/griff-badge2.png' : "/badges/slyth-badge.webp";


    return (
        <div className="profile__row profile__header-row">

            <div className="profile__header-top">
                <div className="profile__logo">
                    <div className="profile__logo-left">
                        {user.photo_url
                            ?
                            <div className="profile__photo-container">
                                <Image className="profile__photo" src={user.photo_url} alt={user.name}
                                       preview
                                       imageStyle={{objectFit: "cover"}}
                                       style={{
                                           width: "220px",
                                           height: "220px",
                                       }}
                                />


                                <div className="profile__name neonText">
                                    {owner ? subscription !== null && subscription !== undefined ?
                                            <>
                                                <img className="profile__premium-image" src="/storage/icons/crown.png"/>
                                                <Tooltip target=".profile__premium-image" position="bottom">
                                                    Действует до {getDateStringFromPlain(subscription.expired_at)}
                                                </Tooltip>
                                            </> :
                                            <></>
                                        :
                                        <></>
                                    }
                                    <img className="profile__badge" src={storageUrl + badgeUrl}/>
                                    {
                                        inFavourite && meInFavourite ? (
                                            <img src="/storage/icons/hearts.png"/>
                                        ) :
                                            <></>
                                    }
                                    {user.name},
                                    {(owner ? <EditableInput
                                        name="age"
                                        placeholder="Укажите возраст"
                                        onChange={handleChange}
                                        info={info}
                                    /> : info.age)}

                                </div>
                            </div>
                            :
                            <>
                                <div className="profile__photo profile__photo_missing" onClick={handleLoadProfilePhoto}>
                                    <Image className="profile__photo" src="/storage/unnamed.png" alt={user.name}
                                           preview
                                           imageStyle={{objectFit: "cover"}}
                                           style={{
                                               width: "220px",
                                               height: "220px",
                                           }}
                                    />
                                </div>
                                <img className="profile__badge" src={storageUrl + badgeUrl}/>
                                {
                                    inFavourite && meInFavourite ? (
                                            <img src="/storage/icons/hearts.png"/>
                                        ) :
                                        <></>
                                }
                                {user.name},
                                {(owner ? <EditableInput
                                    name="age"
                                    placeholder="Укажите возраст"
                                    onChange={handleChange}
                                    info={info}
                                /> : info.age)}
                            </>
                        }
                    </div>

                    <div className="profile__logo-right">
                        <ProfileBaseInfo
                            user={user}
                            info={info}
                            owner={owner}
                            handleChange={handleChange}
                        />
                        <div className="profile__short">
                            <Card title="">
                                {info['about'] !== ''
                                    ? info['about']
                                    : 'О себе: ' + user.name + ' пока ничего не указала'}
                            </Card>
                        </div>

                    </div>

                </div>


                <div className="profile__header profile__other-header">

                        <ProfileShare/>

                        <div className="profile__header-second">
                            <h5>Теги:</h5>
                            {user.tags.map((tag) => (
                                <Chip key={tag.id} label={tag.value}
                                      style={{background: "azure", marginRight: "12px"}}/>
                            ))}
                        </div>




                </div>
            </div>
            <div className="profile__header-bottom">
                <ProfileActions
                    user={user}
                    subscription={subscription}
                    inFavourite={inFavourite}
                    setFavourite={setFavourite}
                    isChatActive={isChatActive}
                    activateChat={activateChat}
                    isLogIn={isLogIn}
                />
            </div>


            {/*<div className="profile__break"></div>*/}


        </div>
    )
}

export default ProfileHeader;
