import React, {useEffect, useState} from "react";
import {Card} from "primereact/card";
import EditableInput from "../UI/EditableInput/EditableInput.jsx";
import useWindowDimensions from "../../hooks/useWindowDimensions.js";
import ProfileQuestionnaire from "./Сontent/ProfileQuestionnaire.jsx";



function ProfileContent ({info, user, meInBan}) {

    useEffect(() => {
        //console.log('info: ' + JSON.stringify(info));
    }, []);


    return (
            meInBan ?
                <div className="profile__row profile__row-blocked">
                    Пользователь ограничил доступ к своей странице
                </div> :
            <>
            {/* About <div className="profile__row">
                <div className="profile__bio">
                    {info.spend_time}
                </div>
            </div> */}

            <ProfileQuestionnaire
                user={user}
                info={info}
            />


        </>
    )
}

export default ProfileContent;