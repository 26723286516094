import {APPLICATION_JSON_HEADER} from "../assets/headers.js";
import {Status} from "./status.js";

export async function fetchPosts(page = 0) {
    let result = {
        ok: false,
        posts: [],
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/posts?page=' + page,
            headers: APPLICATION_JSON_HEADER,
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            posts: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            posts: e.message,
        }
    }
    finally {
        return result
    }
}


export async function fetchPost(postId) {
    let result = {
        ok: false,
        post: [],
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/post/' + postId,
            headers: APPLICATION_JSON_HEADER,
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            post: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            post: e.message,
        }
    }
    finally {
        return result
    }
}