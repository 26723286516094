import "./Contacts.scss";
import {useState} from "react";
import {InputTextarea} from "primereact/inputtextarea";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {sendContactsEmail} from "../../fetchingAPI/mail.js";
import {Image} from "primereact/image";

function Contacts() {

    const [email, setEmail] = useState(null);
    const [subject, setSubject] = useState(null);
    const [content, setContent] = useState(null);

    const onEmailInputChange = (evt) => {
        evt.preventDefault();
        setEmail(evt.target.value);
    }

    const onSubjectInputChange = (evt) => {
        evt.preventDefault();
        setSubject(evt.target.value);
    }

    const onTextAreaChange = (evt) => {
        evt.preventDefault();
        setContent(evt.target.value);
    }

    const onSubmit = async(evt) => {
        evt.preventDefault();
        const result = await sendContactsEmail(email, subject, content);
        console.log('sendContactsEmail result: ' + JSON.stringify(result));
    }



    return(
        <div className="contacts__main">
            <h1>Связаться с нами:</h1>
            <form action="" className="contacts__form" onSubmit={onSubmit}>
                <div className="contacts__email-wrapper">
                    <div className="contacts__email-wrapper-left">
                        <Image src="/storage/static/contacts.webp" />

                    </div>

                    <div className="contacts__email-wrapper-right">
                        <div className="contacts__email-mail">
                            <h4>Email: </h4>
                            <InputText value={email} onChange={onEmailInputChange} className="contacts__email"/>
                        </div>

                        <div className="contects__email-subject">
                            <h4>Тема: </h4>
                            <InputText value={subject} onChange={onSubjectInputChange} className="contacts__subject"/>
                        </div>
                    </div>

                </div>

                <div className="contacts__content-wrapper">
                    <h4>Текст сообщения:</h4>
                    <InputTextarea value={content} onChange={onTextAreaChange} name="text" rows={7}
                                   className="contacts__content"/>
                </div>

                <Button label="Отправить"/>

            </form>
        </div>
    )
}

export default Contacts;
