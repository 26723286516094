import { useNavigate } from 'react-router-dom';
import './ProfileCard.scss';
import {useContext, useEffect, useState} from "react";
import {checkOnline, getDateString} from "../../assets/utility.js";
import {FavouriteContext} from "../../context/FavouriteContext.js";
import {fetchIsMeInFavourite} from "../../fetchingAPI/users.js";

function ProfileCard({
                         user,
                         deactivateChatCallback
}) {

    const navigate = useNavigate();

    let favourite  = useContext(FavouriteContext);

    const [inFavourite, setInFavourite] = useState(false);
    const [meInFavourite, setMeInFavourite] = useState(false);

    /**
     * Проверка, находится ли данный пользователь в любимых у текущего
     * @returns {Promise<void>}
     */
    const checkFavouriteUser = async() => {
        let result = false;
        favourite.forEach((favUser) => {
            if (favUser.id == user?.id)
                result = true;
        })
        setInFavourite(result);
    }

    /**
     * Проверка, находится ли текущий пользователь в избранном у заданного
     * @param userId
     * @returns {Promise<boolean|string>}
     */
    const checkMeInFavourite = async(userId) => {
        const result = await fetchIsMeInFavourite(user?.id);
        //console.log('meInFavourite: ' + JSON.stringify(result));
        if (result.ok) {
            setMeInFavourite(true);
            return result.message;
        }
        setMeInFavourite(false);
        return false;
    }


    useEffect(() => {
        deactivateChatCallback();
        checkFavouriteUser();
        checkMeInFavourite(user?.id);
    }, []);

    useEffect(() => {
        checkFavouriteUser();
        checkMeInFavourite(user?.id);
    }, [user]);


    return (
        <div className="profile-card">
            <div className="profile-card__photo">
                <img
                    key={user.id}
                    loading="lazy"
                    src={user.photo_url}
                    alt={user.name}
                    className='profile-card__img'
                    onClick={() => {
                        navigate('/homepage/users/' + user.id);
                    }}
                />
            </div>
            <div className="profile-card__info">
                <div className="profile-card__nickname"  onClick={() => {
                    navigate('/homepage/users/' + user.id);
                }}>
                    {user.name}, {user.info.age}
                    {checkOnline(user) ? <div className="profile-card__online"/> : <></>}

                    <div className="profile-card__premium">
                        {user.subscription ?
                            <img className="" src="/storage/icons/crown.png"/> :
                            <></>
                        }
                        {
                            inFavourite && meInFavourite ?
                                <img src="/storage/icons/hearts.png"/> :
                                <></>
                        }
                    </div>
                </div>

                <div className="profile-card__location">
                    {user.info.city}
                </div>
                <div className="profile-card__last-visit">
                    {user.last_active_at ? (user.info.gender == 'мужчина' ? 'Был ' : 'Была') + getDateString(user) :  ''}
                </div>
            </div>
        </div>
    )
}

export default ProfileCard;
