import {Dialog} from "primereact/dialog";
import Athentication, {WidgetTypes} from "../Athentication/Athentication.jsx";
import "./AuthenticationModal.scss"

function AuthenticationModal ({visible, onAuthModalHide}) {
    return (
        <Dialog className="login-modal" header="Вход/Регистрация" visible={visible}
                onHide={() => onAuthModalHide()}>
            <Athentication startWidget={WidgetTypes.LOGIN}/>
        </Dialog>
    )
}

export default AuthenticationModal;