import React from "react";

const hobbyFeatures = {
    bike: 'Велосипед',
    cinema: 'Кино',
    games: 'Игры',
    music: 'Музыка',
    self_dev: 'Саморазвитие',
    travel: 'Путешевствия',
    yoga: 'Йога',
    hobby_other: 'Другое',
}


function HobbyCard ({info, Features}) {
    return (
        <div className="profile__col">
            <div className="profile__subtitle">
                Хобби
            </div>
            <div className="profile-list__features">
                <Features
                    info={info}
                    features={hobbyFeatures}
                />
            </div>
        </div>
    )
}

export default HobbyCard;