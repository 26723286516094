import './Chat.scss';
import './ChatTextArea.scss';

import {createRef, useEffect, useRef, useState} from "react";
import ChatContextMenu from "./ChatContextMenu/ChatContextMenu.jsx";
import {ProgressSpinner} from "primereact/progressspinner";
import {stripHtml} from "../../assets/utility.js";

function ChatTextArea({
                          key,
                          chatContents,
                          me,
                          handleQuoteContext,
    }) {

    const self = createRef();
    const [content, setContent] = useState([]);

    useEffect(() => {
        console.log('useEffect content: ' + JSON.stringify(content));
        if (chatContents !== undefined)
            setContent(chatContents);
    }, [chatContents]);

    /**
    * Прокрутка видимой области до последнего сообщения
    */
    useEffect(() => {
        if (Array.isArray(content)) {
            console.log('scroll');
            const textAreas = Array.from(document.getElementsByClassName('chat-main__textarea'));
            if (textAreas.length > 0) {
                const textarea = textAreas[0];
                textarea.scrollTop = textarea.scrollHeight + 200;
            }
        }

    }, [content]);



    useEffect(() => {
        //console.log('ChatTextArea me: ' + JSON.stringify(me));
    }, [me])


    const handleQuoteContextMenu = (data) => {
        console.log('quoteId: ' + data.id);
        handleQuoteContext(data);
    }


    return (
        <div contentEditable key="chat-main__textarea" ref={self} className="chat-main__textarea"
             suppressContentEditableWarning={true}>
            {
                Array.isArray(content)  ? content.map((item, index) => (
                        item.date !== '' ? (
                            <div key={"textarea__div__" + Math.random()}>
                                <p className="textarea__date" key="textarea__date">{item.date}</p>
                                <div className="textarea__content-wrapper" key={"textarea__content-wrapper" + index}>
                                    <img src={item.user !== undefined ? item.user.photo_url : ''}></img>
                                    <ChatContextMenu data={[
                                        {
                                            id: item.id,
                                            title: item.content,
                                            style: me !== undefined && item.user !== undefined && me.id === item.user.id ? "textarea__content-author" : "",
                                            span: item.time,
                                            quote: item.quote,
                                            sticker: item.sticker,
                                            file: item.file,
                                        }
                                    ]} callback={handleQuoteContextMenu}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="textarea__content-wrapper" key={"textarea__content-wrapper" + index}>
                                <img src={item.user !== undefined ? item.user.photo_url : ''}></img>
                                <ChatContextMenu data={[
                                    {
                                        id: item.id,
                                        title: item.content,
                                        style: me !== undefined && item.user !== undefined && me.id === item.user.id ? "textarea__content-author" : "",
                                        span: item.time,
                                        quote: item.quote,
                                        sticker: item.sticker,
                                        file: item.file,
                                    }
                                ]} callback={handleQuoteContextMenu}
                                />
                            </div>
                        )
                    )) :
                    <div className="card chat-main__spinner">
                        {/*<ProgressSpinner
                            style={{width: '50px', height: '50px'}}
                            strokeWidth="8"
                            fill="var(--surface-ground)"

                        />*/}
                        <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>

                    </div>
            }
        </div>

    )
}

export default ChatTextArea;
