import { useState, useEffect } from "react";
import React from "react";
import {BlockUI} from "primereact/blockui";
import {Button} from "primereact/button";
import GiftModal from "../Modal/GiftModal.jsx";
import {fetchBlockUser, fetchIsBlocked, fetchMeBlocked, fetchUnblockUser} from "../../fetchingAPI/users.js";
import {isFeatureExists} from "../../context/SubscriptionContext.js";
import "./ProfileActions.scss";

function ProfileActions({
                    user,
                    subscription,
                    inFavourite,
                    setFavourite,
                    isChatActive,
                    activateChat,
                    isLogIn,
}) {

    const [giftVisible, setGiftVisible] = useState(false);
    const [inBan, setInBan] = useState(false);
    const [blocked, setBlocked] = useState(false);

    const handleSendGiftClick = () => {
        setGiftVisible(true);
    }

    const handleGiftModalHide = () => {
        setGiftVisible(false);
    }

    const handleBanButtonClicked = async() => {
        let result;
        if(inBan) {
            result = await fetchUnblockUser({userId: user.id});
            if (result.ok) {
                setInBan(false);
            }
        } else {
            result = await fetchBlockUser({userId: user.id});
            setInBan(true);
        }
        console.log('result: ' + JSON.stringify(result));

    }

    const checkBan = async() => {
        let result = await fetchIsBlocked(user.id);
        console.log('checkBan: ' + JSON.stringify(result));
        setInBan(result.block);
    }

    useEffect(() => {
        checkBan();
        if (!isFeatureExists(subscription, 'messages-write')) {
            setBlocked(true);
        }
    }, []);



    return (
        <>
            {
                isLogIn ?
                    <div className="profile__actions">
                        <div className="actions">

                            <BlockUI blocked={blocked}
                                     template={<div className="chat-main__lock">
                                         <img className="ribbon-element-text__lock" src="/storage/icons/lock-yellow.png"
                                              style={{fontSize: '3rem'}}/>
                                         Премиум</div>}>

                                <div className="actions__button_wrapper actions__button-like">
                                    {/*inFavourite !== undefined && inFavourite === true ?
                                <>
                                    <div className="actions__button dislike" onClick={setFavourite}>
                                    </div>
                                    <p>Удалить из любимых</p>
                                </> :
                                <>
                                    <div className="actions__button like" onClick={setFavourite}>
                                    </div>
                                    <p>Добавить в любимые</p>
                                </>*/}
                                    <Button label="" onClick={setFavourite}>
                                        <img style={{width: "32px", height: "32px"}}
                                             src={inFavourite !== undefined && inFavourite === true ? '/storage/icons/dislike.png' : '/storage/icons/like.png'}/>
                                    </Button>
                                </div>
                            </BlockUI>


                            <div className="actions__button_wrapper">
                                <Button label="" onClick={activateChat}>
                                    <img style={{width: "32px", height: "32px"}}
                                         src='/storage/icons/talk.png'/>
                                    {/*<p>{!isChatActive ? 'Написать' :
                            'Назад к анкете'}</p> */}
                                </Button>
                            </div>


                            <div className="actions__button_wrapper">
                                <div className="actions__button">
                                    <Button label="" onClick={handleSendGiftClick}>
                                        <img style={{width: "32px", height: "32px"}}
                                             src='/storage/icons/gift.png'/>
                                        {/*<p>Отправить подарок</p> */}
                                    </Button>
                                    <GiftModal user={user} visible={giftVisible} onGiftModalHide={handleGiftModalHide}
                                    />
                                </div>
                            </div>

                            <div className="actions__button_wrapper">

                                <Button label="" onClick={handleBanButtonClicked}>
                                    <img style={{width: "32px", height: "32px"}}
                                         src={inBan ? '/storage/icons/unban-user.png' : '/storage/icons/ban-user.png'}/>
                                    {/*<p>{inBan ? 'Разблокировать' : 'Заблокировать'}</p> */}
                                </Button>

                            </div>

                        </div>
                    </div> :
                    <React.Fragment key={Math.random()}/>
            }
        </>
    )
}

export default ProfileActions;
