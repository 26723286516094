import React, {useContext, useEffect, useRef, useState} from "react";
import { fetchChangeMe, fetchMe } from "../../fetchingAPI/users";
import './Profile.scss';
import ProfileGalleryRibbon from "./Gallery/ProfileGalleryRibbon.jsx";
import MyProfileHeader from "./MyProfileHeader.jsx";
import MyProfileContent from "./MyProfileContent.jsx";
import ProfileGallery from "./Gallery/ProfileGallery.jsx";
import {SubscriptionContext} from "../../context/SubscriptionContext.js";
import useWindowDimensions from "../../hooks/useWindowDimensions.js";



function MyProfile({headerOnly = false, contentOnly = false}) {
    const [user, setUser] = useState(null);
    const { height, width } = useWindowDimensions();
    const [headOnly, setHeadOnly] = useState(headerOnly);
    const [contOnly, setContOnly] = useState(contentOnly);


    const [state, setState] = useState({
        body_type: '',
        eye_color: '',
        hair_color: '',
    });

    const [showGallery, setShowGallery] = useState(false);



    const handleChange = async ({ name, value }) => {
        console.log('name: ' + name + ' value: ' + value);

        const result = await fetchChangeMe({
            field: name,
            value,
            user_id: user.id,
        });

        if (result.ok) {
            getMe();
        }

        setState({
            ...state,
            [name]: value,
        });
    }

    const handleChangeSelect = (evt) => {
        evt.preventDefault();

        let { name, value } = evt.target;
        if (name === 'smoking') {
            console.log('smoking value: ' + value);
            if (value === 'да') {
                value = 1;
            } else if (value === 'нет') {
                value = 0;
            }
            handleChange({ name, value });
        } else {
            handleChange({ name, value });
        }

    }

    const handleGalleryShow = () => {
        setShowGallery(true);
    }

    const handleGalleryHide = () => {
        setShowGallery(false);
    }

    const getMe = async () => {
        const response = await fetchMe();
        if (response.ok) {
            setUser(response.user);
        }
    }

    const handleAvatarUpload = () => {
        getMe();
    }

    useEffect(() => {

        getMe();
    }, []);

    useEffect(() => {
        console.log('width: ' + width);
        if (headerOnly) {
            if (width > 1000) {
                setHeadOnly(false);
            } else {
                setHeadOnly(true);
            }
        }
        if (contentOnly) {
            if (width < 1000) {
                setHeadOnly(true);
            } else {
                setHeadOnly(false);
            }
        }

    }, [width]);


    useEffect( () => {
        console.log('showGallery: ' + showGallery);
    }, [showGallery]);



    if (!user) {
        return <div>Ошибка загрузки пользователя</div>
    }


    const {prefs, info} = user;
    // console.log(info);
    console.log(user);



    return (
        <>
            {headOnly && contOnly ? <></> :

                <div className="profile">
                    {/* Header */}
                        {!contOnly ?
                            <MyProfileHeader
                                user={user}
                                info={info}
                                owner={true}
                                handleChange={handleChange}
                                handleAvatarUpload={handleAvatarUpload}
                                showGallery={showGallery}
                                handleGalleryHide={handleGalleryHide}
                            /> :
                            <></> }
                    { !headOnly ?
                        showGallery === true ? (
                            <ProfileGallery user={user} owner={true} handleGalleryHide={handleGalleryHide}/>
                        ) : (
                            <>
                                <ProfileGalleryRibbon user={user} handleGalleryClick={handleGalleryShow}/>
                                <MyProfileContent
                                    user={user}
                                    prefs={prefs}
                                    info={info}
                                    handleChange={handleChange}
                                    handleChangeSelect={handleChangeSelect}
                                />
                            </>
                    ) : (<></>)
                    }

                </div>
            }

        </>
    )
}

export default MyProfile;
