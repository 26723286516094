import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import "./MessengerCard.scss";
import {fetchUserSubscription} from "../../fetchingAPI/subscriptions.js";
import {stripHtml} from "../../assets/utility.js";

const profileClickLink = '/homepage/users/';

/**
 * Выбор представление даты/времени
 * @param dateTimeString
 */
const setDateTimePresence = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);
    const nowObj = new Date(Date.now());
    const dayNames = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб" ];
    if (dateObj.getUTCFullYear() < nowObj.getUTCFullYear() || dateObj.getUTCMonth() < nowObj.getUTCMonth() || dateObj.getDate() < nowObj.getDate() - 1) {
        return dayNames[dateObj.getDay()] + ' ' + dateObj.getUTCDate();
    }
    else {
        return dateObj.getHours() + ':' + (dateObj.getMinutes() < 10 ? '0' : '') + dateObj.getMinutes();
    }
}

function MessengerCard ({message, isOnline, profileClickedCallback, receiver = false}) {

    const [subscription, setSubscription] = useState(null);

    const getUserSubscription = async() => {
        const result = await fetchUserSubscription(!receiver ? message.user.id : message.receiver.id);
        //console.log('fetchUserSub: ' + JSON.stringify(result));
        setSubscription(result.subscription);
    }

    useEffect(() => {
        getUserSubscription();
    }, []);

    return (
        <NavLink to={!receiver ? `${profileClickLink}${message.user.id}` : `${profileClickLink}${message.receiver.id}`}
                 className="messenger__user" key={message.user.id} onClick={() => {
            profileClickedCallback(!receiver ? message.user.id : message.receiver.id)
        }}>
            {/*<div key={message.user.id} className="messenger__user" onClick={profileClickedCallback(message.user.id)}>*/}
            <div className="messenger__avatar">
                <img src={!receiver ? message.user.photo_url : message.receiver.photo_url}></img>
                {subscription ?
                    <div className="messenger__avatar-premium">
                        <img src="/storage/icons/crown.png" />
                    </div> :
                    <></>
                }
            </div>

            <div className="messenger__info">
                <div className="messenger__username">
                    {!receiver ?
                        message.user.nick_name !== '' ? message.user.nick_name : message.user.name :
                        message.receiver.nick_name !== '' ? message.receiver.nick_name : message.receiver.name
                    }
                    {!receiver ?
                        isOnline(message.user.id) ?
                            <div className="messenger__online"></div> :
                            <></> :
                        isOnline(message.receiver.id) ?
                            <div className="messenger__online"></div> :
                            <></>
                    }
                    <div className="messenger__date">{setDateTimePresence(message.published_date + ' ' + message.published_time)}</div>
                </div>
                <div className="messenger__last-message"
                     style={message.unread > 0 ? {fontWeight: "bold"} : {}}>
                    {message.sticker !== null ? (<img className="messenger__sticker" src={message.sticker}/>) : stripHtml(message.message)}
                    {message.unread > 0 ? (
                        <div className="messenger__unread-count" style={{fontWeight: "normal"}}>
                            {message.unread}
                        </div>) : <></>}
                </div>
            </div>
        </NavLink>
    )
}

export default MessengerCard;
