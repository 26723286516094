import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './main.scss';

import App from './components/App';
import VerifyEmailAddress from "./components/VerifyEmailAddress/VerifyEmailAddress";
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-amber/theme.css";

import { Provider } from "react-redux";
import setupStore from "./store";
import ScrollToTop from "./hooks/scrollToTop.js";

const store = setupStore();

ReactDOM.createRoot(document.getElementById("root")).render(
    <PrimeReactProvider>
        <Provider store={store}>
                <BrowserRouter basename="/">
                    <ScrollToTop />
                    <App />
                </BrowserRouter>
        </Provider>
    </PrimeReactProvider>
);
