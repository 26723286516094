import {APPLICATION_JSON_HEADER} from "../assets/headers.js";
import {Status} from "./status.js";


export async function fetchAreasApi() {
    let result = {
        ok: false,
        areas: [],
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/areas',
            headers: APPLICATION_JSON_HEADER,
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            areas: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            areas: e.message,
        }
    }
    finally {
        return result
    }
}

export async function fetchCurrenciesRates() {
    let result = {
        ok: false,
        base: null,
        rates: {}
    }

    try {

        const response = await fetch('https://www.cbr-xml-daily.ru/latest.js');
        const json = await response.json();
        console.log('json: ' + JSON.stringify(json));

        result = {
            ...result,
            ok: true,
            base: json.base,
            rates: json.rates,
        }

    }
    catch(e) {
        result = {
            ...result,
            areas: e.message,
        }
    }
    finally {
        return result
    }

}
