import {FileUpload} from "primereact/fileupload";
import {Image} from "primereact/image";
import React, {useContext, useEffect, useReducer, useRef, useState} from "react";
import {fetchDeleteMedia, fetchMarkMedia, fetchMedia} from "../../../fetchingAPI/media.js";
import "./ProfileGalleryRibbon.scss"
import handleBeforeSend from "../../../utils/upload.js";
import {fetchAddLike, fetchLikes} from "../../../fetchingAPI/likes.js";
import {Tooltip} from "primereact/tooltip";
import LoadMediaModal from "../../Modal/LoadMediaModal.jsx";
import ProfileRibbonCard from "./Cards/ProfileRibbonCard.jsx";
import {sendNotify} from "../../../chatAPI/laravel-echo.js";
import {isFeatureExists, SubscriptionContext} from "../../../context/SubscriptionContext.js";

function ProfileGalleryRibbon ({user, owner = true, handleGalleryClick}) {

    const fileUploadUrl = '/upload-media';
    const fileUploadRef = useRef(null);
    const [media, setMedia] = useState(null);
    const [countPhoto, setCountPhoto] = useState(0);
    const [countVideo, setCountVideo] = useState(0);
    const [likes, setLikes] = useState(null);
    const [likesByImage, setLikesByImage] = useState(null);

    const [toolTips, setTooltips] = useState([]);

    const [mediaModalVisible, setMediaModalVisible] = useState(false);

    const [uploadedId, setUploadedId] = useState(false);
    const {subscription, setSubscription} = useContext(SubscriptionContext);



    /**
     * Обработчик завершения загрузки файла
     */
    const onUpload = (event) => {
        const response = JSON.parse(event.xhr.responseText);
        setUploadedId(response.id);
        setMediaModalVisible(true);
        if (user !== undefined) {
            getMedia();
        }
    }

    const onMediaDialogHide = () => {
        setMediaModalVisible(false);
    }

    /**
     * Обработчик выбора доступа к медиа
     * @param privacy
     * @returns {Promise<void>}
     */

    const onMediaDialogChoose = async(uploadedId, privacy) => {
        onMediaDialogHide();
        const data = {
            userId: user.id,
            attachmentId: uploadedId,
            privacy: privacy,
        }
        const result = await fetchMarkMedia(data);
        console.log('result: ' + JSON.stringify(result));
        await getMedia();

    }

    const getMedia = async() => {
        const result = await fetchMedia(user.id);
        //console.log('media result: ' + JSON.stringify(result));
        setMedia(result.media.original)
    }

    const isMediaLiked = (attachmentId) => {
        //console.log(typeof attachmentId);
        let result = false;
        if (likes !== null) {
            likes.forEach((like) => {
                if (like.attachment_id === attachmentId) {
                    result = true;
                }
            });
        }

        return result;
    }

    const registerLikeClickListener = (likeButton) => {
        const likeClickListenerId = likeButton.addEventListener('click', function (event) {
            console.log('like clicked');
            handleLikeClicked(likeButton);
            event.stopPropagation();
            likeButton.removeEventListener('click', likeClickListenerId);

            //getMedia();
        });
    }

    const registerLikeMouseListeners = (likeButton) => {
        const likeMouseEnterListenerId = likeButton.addEventListener('mouseenter', function (event) {
            console.log('like entered');
            //handleLikeClicked(likeButton);
            likeButton.removeEventListener('mouseenter', likeMouseEnterListenerId);
        });
    }

    const countLikes = (attachmentId) => {
        //console.log('attachmentId: ' + attachmentId);
        let result = 0;
        if (likesByImage !== null) {
            Object.keys(likesByImage).forEach((keyName, i) => {
                //console.log('lieksByImage: ' + JSON.stringify(likesByImage[keyName]));
                //console.log('keyName: ' + keyName + ' i: ' + i);
                if (keyName == attachmentId) {
                    //console.log('likesVByImage: ' + JSON.stringify(likesByImage[keyName]));
                    //console.log('len: ' + likesByImage[keyName].length);
                    result = likesByImage[keyName].length;
                    //return result;
                }
            });
        }

        return result;
    }

    const addLikeButton = () => {

        let images = Array.from(document.getElementsByClassName('ribbon-element-image'));
        //console.log('elm: ' + JSON.stringify(elm));
        images.forEach((image, i) => {
            const attachmentId = getAttachmentIdFromRoot(image);
            //console.log('attachmentId: ' + attachmentId);
            const isLiked = isMediaLiked(attachmentId);
            //console.log('isLiked: ' + isLiked);
            let likesCounter = countLikes(attachmentId);
            //console.log('likesCounter: '  + likesCounter);

            const imageButtonsWrapper = document.createElement("div");
            imageButtonsWrapper.className = 'image-buttons-wrapper';

            const likeButtonWrapper = document.createElement("div")
            likeButtonWrapper.className = 'image-like-wrapper';
            const likeButton = document.createElement("img");
            likeButton.className = 'tooltip-owner-' + attachmentId + " " +  (likesCounter > 0 ? 'image-liked' : 'image-like');
            likeButton.src = "/storage/icons/heart.png";
            likeButton.textContent = likesCounter;
            likeButtonWrapper.appendChild(likeButton);

            imageButtonsWrapper.appendChild(likeButtonWrapper);
            let text = document.createTextNode(likesCounter);
            likeButtonWrapper.appendChild(text);
            image.insertBefore(imageButtonsWrapper, image.childNodes[2]);

            registerLikeClickListener(likeButton);
            //registerLikeMouseListeners(likeButton);


        });
    }

    const removeLikeButtons = () => {
        const buttonNames = ['image-buttons-wrapper'];
        buttonNames.forEach((name) => {
            let likeButtons = Array.from(document.getElementsByClassName(name));
            likeButtons.forEach((button) => {
                button.remove();
            })
        })

    }

    const onDeleteHandler = () => {
       // removeLikeButtons();
        //removeDeleteButtons();
    }

    const addDeleteButtons = () => {
        //addDeleteButton(getMedia, onDeleteHandler);
    }

    const handleDeleteClick = async(mediaId) => {
        await fetchDeleteMedia(mediaId);
        await getMedia();

    }

    const removeDeleteButtons = () => {
        const buttonNames = ['toolbar-delete'];
        buttonNames.forEach((name) => {
            let likeButtons = Array.from(document.getElementsByClassName(name));
            likeButtons.forEach((button) => {
                button.remove();
            })
        })
    }

    const getLikes = async() => {
        const result = await fetchLikes(user.id);
        //console.log('result: ' + JSON.stringify(result));
        setLikes(result.likes);
    }

    const countPhotoVideo = () => {
        let countPhoto = 0;
        let countVideo = 0;
        media.forEach((m) => {
            if (m.mime.startsWith('image')) {
                countPhoto += 1;
            } else if (m.mime.startsWith('video')) {
                countVideo += 1;
            }
        });
        setCountPhoto(countPhoto);
        setCountVideo(countVideo);

    }

    useEffect(() => {
        //console.log('pg ribbon user: ' + JSON.stringify(user));
        if (user !== undefined) {
            getMedia();
        }
    }, [user]);


    useEffect(() => {
        //console.log('media: ' + JSON.stringify(media));
        if (media !== null) {
            countPhotoVideo();
            getLikes();

        }
    }, [media]);

    const sortLikesByImage = () => {
        let result = {};
        likes.forEach((like) => {
            let data = {
                senderId: like.sender_id,
                user: like.user,
                created_at: like.created_at,
                updated_at: like.updated_at,
            }
            if (result[like.attachment_id] === undefined) {
                result[like.attachment_id] = [data];
            } else {
                result[like.attachment_id].push(data);
            }

        });
        //console.log('sort result: ' + JSON.stringify(result));
        setLikesByImage(result);
    }

    useEffect(() => {
        if (likes !== null)
            sortLikesByImage();


    }, [likes]);

    useEffect(() => {
        //console.log('likesByImage: ' + JSON.stringify(likesByImage));
        //removeLikeButtons();
        //addLikeButton();
        if (owner) {
            //removeDeleteButtons();
            //addDeleteButtons();
        }
        setTooltips([1]);
    }, [likesByImage]);



    const getAttachmentIdFromRoot = (container) => {
        const span = container.childNodes[0];
        //console.log('container childNodes: ' + JSON.stringify(container.childNodes[0]));
        const imgClass = span.childNodes[0].className;
        const attachmentId = parseInt(imgClass.split('_').at(-1));
        //console.log('attachmentId: ' + attachmentId);
        return attachmentId;
    }


    const addLike = async(data) => {
        const result = await fetchAddLike(data);
        if (result.message == 'created') {
            await sendNotify('notify_' + user?.id, 'like', data.attachmentId, data.url);
        }
        getLikes();

    }

    const handleLikeClicked = (media) => {
        const canLike = isFeatureExists(subscription, 'likes');
        console.log('canLike: ' + JSON.stringify(canLike));
        if (!owner && isFeatureExists(subscription, 'likes')) {
            const data = {
                attachmentId: media.id,
                url: media.url,
            }
            addLike(data);
        }
    }

    let isLikeFound = false;
    let count = 0;

    function renderMediaWithLikes(i, media, keyName) {
        //console.log('renderMediaWithLikes');
        isLikeFound = true;
        let className = "tooltip-owner-" + i;
        media.group === 'private' ? className += ' private-image' : className;
        return (
            <div className="profile__row ribbon-element-image" key={Math.random()}>
                <ProfileRibbonCard
                    owner={owner}
                    key={Math.random()}
                    className={className}
                    media={media}
                    likesByImage={likesByImage}
                    handleDeleteClick={handleDeleteClick}
                    handleLikeClick={handleLikeClicked}
                />

                <Tooltip key={Math.random()} className="tooltip-like" target={".tooltip-owner-" + media?.id} position="right bottom">
                    <p>{"Понравилось " + likesByImage[keyName].length}</p>
                    {
                        likesByImage[keyName].map((like) => (
                            <img key={Math.random()} src={like.user.photo_url}/>
                        ))
                    }

                </Tooltip>
            </div>
        )
    }


    const renderMediaWithoutLikes = (media) => {
        //console.log('renderMediaWithoutLikes');
        return (
            <div className="profile__row ribbon-element-image" key={Math.random()}>
                <ProfileRibbonCard
                    owner={owner}
                    key={Math.random()}
                    className={media.group === 'private' ? 'private-image' : ''}
                    media={media}
                    likesByImage={likesByImage}
                    handleDeleteClick={handleDeleteClick}
                    handleLikeClick={handleLikeClicked}
                />
            </div>
        )
    }


    const photosCount = 5;
    const ownerPhotosCount = 5;


    return (
        <div className="profile__row ribbon">

            {owner === true &&  media !== null && media.length > 0 ?
                (
                    <>
                        <div className="profile__row ribbon-element">
                            <div className="ribbon-element__container">
                                <FileUpload
                                    mode="basic" name="file_name" url={fileUploadUrl} accept="image/*"
                                    maxFileSize={50000000}
                                    onUpload={onUpload} auto chooseLabel=" "
                                    chooseOptions={{icon: "chat-main__upload-icon"}} className="profile__photo-add"
                                    onBeforeSend={(event) => {
                                        handleBeforeSend(event);
                                    }} style={{width: "135px", height: "65px", display: "flex"}}
                                />
                                Фото ({countPhoto})
                            </div>

                            <div className="ribbon-element__container ribbon-element__container-video">
                                <FileUpload
                                    mode="basic" name="file_name" url={fileUploadUrl} accept="video/*"
                                    maxFileSize={50000000}
                                    onUpload={onUpload} auto chooseLabel=" "
                                    chooseOptions={{icon: "chat-main__upload-icon"}} className="profile__video-add"
                                    onBeforeSend={(event) => {
                                        handleBeforeSend(event);
                                    }} style={{width: "135px", height: "40px", display: "flex"}}
                                />
                                Видео ({countVideo})
                            </div>


                            <img src="/storage/icons/image-gallery.png" onClick={handleGalleryClick}></img>
                        </div>


                        <LoadMediaModal uploadedId={uploadedId} visible={mediaModalVisible}
                                        onMediaDialogHide={onMediaDialogHide}
                                        onMediaDialogChoose={onMediaDialogChoose}/>
                    </>
                ) :  media !== null && media.length > 0  ?
                (
                    <div className="profile__row ribbon-element">
                        <img src="/storage/icons/image-gallery.png" onClick={handleGalleryClick}></img>
                        <div className="profile_row ribbon-element-text">
                            <p>Фото ({countPhoto})</p>
                            {countVideo > 0 ? <a href="/homepage/premium">
                                <img className="ribbon-element-text__lock" src="/storage/icons/lock-pink.png"/>
                            </a> : <></>}

                            <Tooltip target=".ribbon-element-text__lock">
                                <div className=".ribbon-element-text__lock-description">
                                    <img className="ribbon-element-text__lock-image" src="/storage/icons/crown.png"/>
                                    Премиум чтобы разблокировать
                                </div>

                            </Tooltip>
                            <p>Видео ({countVideo})</p>
                        </div>

                    </div>
                ) :
                (
                    <React.Fragment key={Math.random()} />
                )
            }

            <div className="ribbon-image-wrapper">
                <div className="ribbon-image-container" style={{
                    height: media !== null && media.length > 0 ? "100%" : "fit-content",
                    display: media !== null && media.length > 0 ? "grid" : "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: media !== null && media.length > 0 ? "0" : "inherit",
                    marginRight: media !== null && media.length > 0 ? "0" : "inherit"
                    }}>


                    {
                        media !== null && media.length > 0 ? media.map((m, index) => {
                            isLikeFound = false;
                            if (owner === true && index < ownerPhotosCount || !owner && index < photosCount) {
                                if (likesByImage !== null && Object.keys(likesByImage).length > 0) {
                                    return (
                                        Object.keys(likesByImage).map((keyName, i) => {
                                            //console.log('lieksByImage: ' + JSON.stringify(likesByImage[keyName]));
                                            if (keyName == m.id) {
                                                count += 1;
                                                //console.log('i found: ' + i + ' mediaId: ' + m.id + ' keyName: ' + keyName + 'object.keys: '
                                                //    + Object.keys(likesByImage).length);

                                                return renderMediaWithLikes(i, m, keyName);

                                            }

                                            if (i === Object.keys(likesByImage).length - 1 && !isLikeFound && m.url !== null
                                                && (owner === true && count < ownerPhotosCount || !owner && count < photosCount)) {
                                                //console.log(isLikeFound + ' i not found: ' + i + ' mediaId: ' + m.id + 'keyName: ' + keyName + 'object.keys: ' + Object.keys(likesByImage).length);
                                                //console.log('media: ' + JSON.stringify(media));
                                                count += 1;
                                                return renderMediaWithoutLikes(m);

                                            } else {
                                                return (
                                                    <React.Fragment key={Math.random()}/>
                                                )
                                            }
                                            // map
                                        })
                                    )
                                } else {
                                    // likesByImage == null
                                    return renderMediaWithoutLikes(m);
                                }

                            } else {
                                return (<React.Fragment key={Math.random()}/>)
                            }

                        }) : owner === false ?
                            (
                                <p>{user.name} пока не загрузил фото/видео</p>
                            ) :
                            (
                                <p>Загрузите фото/видео, чтобы другие пользователи смогли лучше узнать вас</p>
                            )
                    }
                </div>
            </div>


        </div>
    )
}

export default ProfileGalleryRibbon;
