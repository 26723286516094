import React, {useEffect, useState} from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions.js";
import SpendTimeCard from "./Cards/SpendTimeCard.jsx";
import LookCard from "./Cards/LookCard.jsx";
import LifestyleCard from "./Cards/LifestyleCard.jsx";
import HobbyCard from "./Cards/HobbyCard.jsx";
import "./ProfileQuestionnaire.scss";


function Features({ info, features }) {

    useEffect(() => {
        //console.log('feaut: ' + JSON.stringify(features));
    }, []);


    const isShowFeature = (feature) => {
        if (typeof feature === 'string') {
            return feature.length > 0;
        }

        return feature === 1;
    }

    const getFeature = ({ key, info, map }) => {
        if (typeof info[key] === 'number') {
            return map[key];
        }

        return info[key];
    }
    let featuresFilled = 0;

    return (
        <div className="pref-enums">
            {
                Object.entries(features).map(([key], index) => {
                    if (isShowFeature(info[key])) {
                        featuresFilled += 1;
                        return <div key={key} className="pref-enums__item">{getFeature({ key, info, map: features })}</div>
                    }
                    if (index === Object.entries(features).length - 1 && featuresFilled == 0) {
                        return <div key={Math.random()} className="prefs-enums__item">Не указаны</div>
                    }
                })
            }
        </div>
    )
}

function ProfileQuestionnaire ({user, info}) {

    const notFilledMessage = user.name + ' пока ничего не указала';
    const notFilledShortMessage = 'не указано';

    const { height, width } = useWindowDimensions();
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (width < 1000) {
            setShow(false);
        } else {
            setShow(true);
        }
    }, [width]);

    return (
        <>
            {show ? (
                <>
                    <div className="profile__row profile__row-question">
                        <div className="profile__question-minimize">
                            <p className="neonText">Анкета</p>
                            <img src={show ? "/storage/icons/window-minimize.png" : "/storage/icons/window-maximize.png"}
                                 onClick={() => {
                                     {
                                         setShow((prev) => !prev)
                                     }
                                 }}></img>
                        </div>
                    </div>

                    <div className="profile__row profile__row-question-wrapper">

                        <SpendTimeCard info={info} notFilledMessage={notFilledMessage}/>


                        {/* Features */}
                        <div className="profile__row profile__row-question profile__row-question-cards">

                            <LookCard info={info} Features={Features} notFilledShortMessage={notFilledShortMessage}/>
                            <LifestyleCard info={info} notFilledShortMessage={notFilledShortMessage}/>
                            <HobbyCard info={info} Features={Features}/>

                        </div>
                    </div>
                  


                </>
            ) : (
                <div className="profile__row profile__row-question">
                    <div className="profile__question-minimize">
                        <p className="neonText">Анкета</p>
                        <img src={show ? "/storage/icons/window-minimize.png" : "/storage/icons/window-maximize.png"}
                             onClick={() => {
                                 {
                                     setShow((prev) => !prev)
                                 }
                             }}></img>
                    </div>
                </div>
            ) }
        </>
    )
}

export default ProfileQuestionnaire;