import "./PaymentCancel.scss";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";

function PaymentCancel () {

    const navigate = useNavigate();

    return (
        <div className="profile__row">
            <div className="payment-cancel__wrapper">
                <div className="payment-cancel__image">
                    <img src="/storage/reactions/payment-cancel.png"/>
                </div>
                <h4>Платеж был отменен</h4>
                <div className="payment-cancel__button-wrapper">
                    <Button type="button" label="В профиль"  severity="warning"
                            onClick={() => {navigate('/homepage/me')}} raised></Button>
                </div>
            </div>
        </div>
    )
}

export default PaymentCancel;