import React, {createContext, useEffect, useState} from "react";
import {Routes, Route, useNavigate, Navigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Main from "./Main/Main";
import Home from "./Home/Home.jsx";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import VerifyEmailAddress from "./VerifyEmailAddress/VerifyEmailAddress.jsx";
import PasswordReset from "./PasswordReset/PasswordReset.jsx";

import { logIn, logOut } from "../store/reducers/LoginSlice.js";

import './App.scss';
/*import '../../css/webfonts/all.min.css'*/
import { fetchLoginState } from "../fetchingAPI/authentication.js";
import {fetchFavourite} from "../fetchingAPI/users.js";
import {ToastContextProvider} from "../context/ToastContext.jsx";
import {Helmet} from "react-helmet";

function App() {
    const { isLogIn } = useSelector(state => state.loginReducer);
    const dispatch = useDispatch();
    const location = useLocation().pathname

    const navigate = useNavigate();

    const [showFilters, setShowFilters] = useState(true);

    const setLoginState = async () => {
        const state = await fetchLoginState();
        if (state) {
            dispatch(logIn());
            console.log('location: ' + location);
            navigate(location !== '/' ? location : '/homepage/users');
        } else {
            dispatch(logOut());
            if (location !== '/homepage/premium' && !location.includes('/homepage/blog') && !location.includes('/homepage/users')) {
                navigate('/login');
            }
        }
    }

    const checkFavourite = async() => {
        const result = await fetchFavourite();
        //setFavourite(result.message);
    }

    /**
     * Скрытие хидера для главной страницы
     */

    useEffect(() => {
        const location =  window.location.href;
        if (!location.includes('/homepage')) {
            const headers = Array.from(document.getElementsByTagName('header'));
            headers.forEach((header) => {
                header.style.display = 'none';
            })

        } else {
            const headers = Array.from(document.getElementsByTagName('header'));
            headers.forEach((header) => {
                header.style.display = 'flex';
            })
        }
    }, [window.location.href]);

    useEffect( () => {
        // fetchLoginState();
        setLoginState();
        //checkFavourite();
    }, [isLogIn]);

    const onFilterClick = () => {
        setShowFilters(!showFilters);
    }

    const title = "Itdating сайт – первая онлайн-платформа для знакомств «айтишников»";
    const description = " Тысячи одиноких девушек и парней найдут здесь людей, друзей и любовь! Знакомства для айтишников Уютная атмосфера, никаких посторонних и только люди!";

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <link rel="canonical" href={location}/>
            </Helmet>


            <ToastContextProvider>
                <Header isLogIn={isLogIn} showFilters={showFilters} onFilterClick={onFilterClick}/>
                {/*<FavouriteContext.Provider value={favourite}>*/}
                    <Routes>
                        <Route path='/login' element={<Main>
                            <Helmet>
                                <meta charSet="utf-8"/>
                                <title>{title}</title>
                                <meta name="description" content={description}/>
                                <link rel="canonical" href={location}/>
                            </Helmet>
                        </Main>} />
                        <Route path='/homepage/*' element={<Home showFilters={showFilters} isLogIn={isLogIn}/>} />
                        <Route path='/email/verify/:id/hash' element={<VerifyEmailAddress />} />
                        <Route path='/reset-password/:token' element={<PasswordReset />} />

                        {/* <Route
                        path='/'
                        element={<Navigate to={isLogIn ? '/login' : '/homepage/users'} />}
                    /> */}

                        <Route path='*' element={<div>Страницы не существует</div>} />
                    </Routes>
                {/*</FavouriteContext.Provider>*/}

                <Footer />
            </ToastContextProvider>

        </>
    );
}

export default App;
