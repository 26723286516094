import {NavLink} from "react-router-dom";
import React, {useEffect, useRef} from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions.js";

function MessengerPremiumCard () {

    const premiumRef = useRef();
    const { height, width } = useWindowDimensions();

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (navigator.userAgent.includes('Chrome') && premiumRef.current !== undefined && width > 1000 && width < 1400) {
            premiumRef.current.style.writingMode = 'vertical-rl';
        }
    }, [premiumRef.current]);

    return (
        <div className="messenger__userrow messenger__premiumrow">
            <NavLink to="/homepage/premium"
                     className="messenger__user user-premium" key={Math.random()} ref={premiumRef}>
                <img className="messenger__crown" src="/storage/icons/crown.png"/>
                Премиум
            </NavLink>
        </div>
    )
}

export default MessengerPremiumCard;