/** Сообщения **/
import {APPLICATION_JSON_HEADER} from "../assets/headers.js";
import {Status} from "./status.js";


export async function fetchLastMessages(userId, data) {
    let result = {
        ok: false,
        message: '',
    }

    try {
        const response = await axios({
            method: 'post',
            url: '/last-messages/' + userId,
            headers: APPLICATION_JSON_HEADER,
            data: data
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            message: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            message: e.message,
        }
    }
    finally {
        return result
    }
}

export async function markMessagesAsRead(data) {
    let result = {
        ok: false,
        message: '',
    }

    try {
        const response = await axios({
            method: 'post',
            url: '/messages/read',
            headers: APPLICATION_JSON_HEADER,
            data: data
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            message: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
        }
    }
    finally {
        return result
    }
}

export async function markToastAsRead(data) {
    let result = {
        ok: false,
        message: '',
    }

    try {
        const response = await axios({
            method: 'post',
            url: '/messages/toast',
            headers: APPLICATION_JSON_HEADER,
            data: data
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            message: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
        }
    }
    finally {
        return result
    }
}
