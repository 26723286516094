/**
     * Установка X-CSRF-TOKEN в зголовки запроса
     * @param event
     */
import {X_CSRF_TOKEN} from "../assets/headers.js";


const handleBeforeSend = (event) => {
    console.log('handle before upload');
    console.log('handleBeforeSend: ' + JSON.stringify(event));
    event.xhr.setRequestHeader("X-CSRF-TOKEN", X_CSRF_TOKEN);
}

export default handleBeforeSend;
