import {Card} from "primereact/card";
import {Chip} from "primereact/chip";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./Profile.scss"
import "./ProfileHeader.scss"
import Modal from "../UI/Modal/Modal.jsx";
import AddPhotoForm from "../AddPhotoForm/AddPhotoForm.jsx";
import ProfileBaseInfo from "./Header/ProfileBaseInfo.jsx";
import ProfileShare from "./Header/ProfileShare.jsx";
import handleBeforeSend from "../../utils/upload.js";
import {FileUpload} from "primereact/fileupload";
import EditableInput from "../UI/EditableInput/EditableInput.jsx";
import {Image} from "primereact/image";
import {isTagExists} from "./Misc/utils.js";
import {Button} from "primereact/button";
import {fetchSubscribe, fetchSubscription} from "../../fetchingAPI/subscriptions.js";
import {SubscriptionContext} from "../../context/SubscriptionContext.js";
import {Tooltip} from "primereact/tooltip";
import {getDateStringFromPlain} from "../../assets/utility.js";
import {MdOutlineAddAPhoto} from "react-icons/md";

function MyProfileHeader ({
                            user,
                            info,
                            owner,
                            showGallery,
                            handleChange,
                            handleAvatarUpload,
                            handleGalleryHide,
}) {

    const [isOpen, setIsopen] = useState(false);
    const showModal = () => setIsopen((prev) => !prev);

    const [activeAddPhoto, setActiveAddPhoto] = useState(false);
    const storageUrl = '/storage';
    const fileUploadUrl = '/upload-avatar';

    const navigate = useNavigate();

    const {subscription, setSubscription} = useContext(SubscriptionContext);


    const openAddPhoto = () => {
        setActiveAddPhoto(true);
    }

    const closeAddPhoto = () => {
        setActiveAddPhoto(false);
    }

    const handleLoadProfilePhoto = () => {
        const loadInput = document.getElementById('files');
        loadInput.click();
    }

    const onUpload = () => {
        handleAvatarUpload();
    }

    const badgeUrl = isTagExists(user.tags, 'Гриффиндор') ? '/badges/griff-badge2.png' : "/badges/slyth-badge.webp";


    const subscribe = async() => {
        navigate('/homepage/premium');
        //let result = await fetchSubscribe({planId: 25});
        //console.log('subscribe result: ' + JSON.stringify(result));
        //await getSubscription();
    }

    const showGalleryButtons = () => (
        <div className="flex align-items-center gap-2 p-3" style={{cursor: 'pointer'}} style={{display: "flex"}}>

            <div className="profile-gallery__back" onClick={handleGalleryHide}></div>

            <FileUpload
                mode="basic" name="file_name" url={fileUploadUrl} accept="image/*" maxFileSize={50000000}
                onUpload={onUpload} auto chooseLabel=" "
                chooseOptions={{icon: "chat-main__upload-icon"}} className="profile-gallery__add-photo"
                onBeforeSend={(event) => {
                    handleBeforeSend(event);
                }}
            />

            <FileUpload
                mode="basic" name="file_name" url={fileUploadUrl} accept="video/*" maxFileSize={50000000}
                onUpload={onUpload} auto chooseLabel=" "
                chooseOptions={{icon: "chat-main__upload-icon"}} className="profile-gallery__add-video"
                onBeforeSend={(event) => {
                    handleBeforeSend(event);
                }}
            />
        </div>
    )


    useEffect(() => {
        /*console.log('MyProfileHeader: ' + JSON.stringify(user));
        console.log('MyProfileHeader sub: ' + JSON.stringify(subscription));*/;
        //getSubscription();
    }, []);

    return (
        <div className="profile__row profile__row-header">
            <div className="profile__logo" onClick={openAddPhoto}>

                <div className="profile__logo-left">
                    {user.photo_url
                        ?
                        <div className="profile__photo-container">
                            <Image className="profile__photo" src={user.photo_url} alt={user.name}
                                   preview
                                   imageStyle={{objectFit: "cover"}}
                                   style={{
                                       width: "220px",
                                       height: "220px",
                                   }}
                            />


                            <div className="profile__name neonText">
                                {owner ? subscription !== null && subscription !== undefined ?
                                        <>
                                            <img className="profile__premium-image" src="/storage/icons/crown.png"/>
                                            <Tooltip target=".profile__premium-image" position="bottom">
                                                Действует до {getDateStringFromPlain(subscription.expired_at)}
                                            </Tooltip>
                                        </> :
                                        <></>
                                    : userSubscription !== null ?
                                        <>
                                            <img className="profile__premium-image" src="/storage/icons/crown.png"/>
                                            <Tooltip target=".profile__premium-image" position="bottom">
                                                Действует до {getDateStringFromPlain(userSubscription.expired_at)}
                                            </Tooltip>
                                        </> :
                                        <></>
                                }
                                <img className="profile__badge" src={storageUrl + badgeUrl}/>
                                {user.name},
                                {(owner ? <EditableInput
                                    name="age"
                                    placeholder="Укажите возраст"
                                    onChange={handleChange}
                                    info={info}
                                /> : info.age)}

                            </div>
                        </div>
                        :
                        <div className="profile__name neonText">
                            <div className="profile__photo profile__photo_missing" onClick={handleLoadProfilePhoto}>
                                <Image className="profile__photo" src="/storage/unnamed.png" alt={user.name}
                                    preview
                                    imageStyle={{objectFit: "cover"}}
                                    style={{
                                        width: "220px",
                                        height: "220px",
                                    }}
                                />
                            </div>
                            <img className="profile__badge" src={storageUrl + badgeUrl}/>
                            {user.name},
                            {(owner ? <EditableInput
                                name="age"
                                placeholder="Укажите возраст"
                                onChange={handleChange}
                                info={info}
                            /> : info.age)}
                        </div>
                    }



                </div>

                <div className="profile__logo-right">
                    <ProfileBaseInfo
                        user={user}
                        info={info}
                        owner={owner}
                        handleChange={handleChange}
                    />
                    <div className="profile__short">
                        <Card title="">
                            <EditableInput
                                name='about'
                                placeholder={info['about'] !== ''
                                    ? info['about']
                                    : 'Расскажите в двух словах о себе или своем настроении'}
                                onChange={handleChange}
                                info={info}
                            />
                        </Card>
                    </div>
                    <FileUpload
                        mode="basic" name="file_name" url={fileUploadUrl} accept="image/*" maxFileSize={50000000}
                        onUpload={onUpload} auto chooseLabel=" "
                        chooseOptions={{icon: "chat-main__upload-icon"}} className="profile__photo-container-add"
                        onBeforeSend={(event) => {
                            handleBeforeSend(event);
                        }}
                    />
                </div>


            </div>

                <div className="profile__header">


                    <div className="profile__header-right">

                        <ProfileShare user={user}/>


                    </div>

                    <div className="profile__break"></div>
                    <div className="profile__header-second">
                        <h5>Теги:</h5>
                        {user.tags.map((tag) => (
                            <Chip key={tag.id} label={tag.value} style={{background: "azure", marginRight: "12px"}}/>
                        ))}
                    </div>

                    {subscription == null ?
                        <Button  icon="" className="profile__header-premium-button mr-2"
                                onClick={subscribe}
                                aria-controls="popup_menu_right" aria-haspopup>
                            <img src="/storage/icons/crown.png"/>
                                Премиум
                        </Button>
                        : <></> }


                    <Modal
                        active={activeAddPhoto}
                        onOpen={openAddPhoto}
                        onClose={closeAddPhoto}
                    >
                        <AddPhotoForm
                            // user={user}
                            name='photo_url'
                            onClose={closeAddPhoto}
                            onSubmit={handleChange}
                        />
                    </Modal>

                </div>




            {/*<div className="profile__actions">
                        <div className="actions">
                            <div className="actions__button">
                                Подмигнуть
                            </div>
                            <div className="actions__button">
                                Написать
                            </div>
                            <div className="actions__button">

                            </div>
                            <div className="actions__button">
                                Добавить в избранные
                            </div>
                        </div>
                    </div>*/}
        </div>
    )
}

export default MyProfileHeader;
