import {APPLICATION_JSON_HEADER} from "../assets/headers.js";
import {Status} from "./status.js";

export async function fetchGifts() {
    let result = {
        ok: false,
        gifts: [],
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/gifts',
            headers: APPLICATION_JSON_HEADER,
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            gifts: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            gifts: e.message,
        }
    }
    finally {
        return result
    }
}

export async function fetchUserGifts() {
    let result = {
        ok: false,
        gifts: [],
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/user-gifts',
            headers: APPLICATION_JSON_HEADER,
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            gifts: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            gifts: e.message,
        }
    }
    finally {
        return result
    }
}

export async function fetchAddGiftToUser(data) {
    let result = {
        ok: false,
        message: '',
    }

    try {
        const response = await axios({
            method: 'post',
            url: '/gift',
            headers: APPLICATION_JSON_HEADER,
            data: data,
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            message: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            message: e.message,
        }
    }
    finally {
        return result
    }
}