import './Footer.scss';
import {FacebookIcon, RedditIcon, TelegramIcon, TwitterIcon} from "react-share";
import React from "react";
import {useNavigate} from "react-router-dom";

function Footer() {

    const socialIconsWidth = "32px";

    const navigate = useNavigate();

    return (
        <footer className="footer">
            <div className="footer__contacts">
                <div className="footer__row">
                    <div className="socials">
                        <h3 className="socials__title">Социальные сети</h3>
                        <ul className="socials__items">
                            <div className="socials__item">
                                <a href="https://facebook.com">
                                    <FacebookIcon width={socialIconsWidth}/>
                                </a>
                            </div>
                            <div className="socials__item">

                                <a href="https://telegram.org">
                                    <TelegramIcon width={socialIconsWidth}/>
                                </a>

                            </div>
                            <div className="socials__item">
                                <a href="https://reddit.com">
                                    <RedditIcon width={socialIconsWidth}/>
                                </a>

                            </div>
                            <div className="socials__item">
                                {/* <a href="https://twitter.com">
                                        <i className="fa">𝕏</i>
                                    </a> */}
                                <TwitterIcon width={socialIconsWidth}/>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="footer__row">
                    <div className="footer__btns">
                        <button className="footer__btn" onClick={() => {
                            navigate('/homepage/blog');
                        }}>Блог
                        </button>
                        <button className="footer__btn" onClick={() => {
                            navigate('/homepage/premium');
                        }}>Премиум
                        </button>
                        <button className="footer__btn" onClick={() => {
                            navigate('/homepage/contacts');
                        }}>Контакты
                        </button>
                        <button className="footer__btn" onClick={() => {
                            navigate('/homepage/about');
                        }}>О нас
                        </button>
                    </div>
                </div>
            </div>
            <div className="footer__copy">2024 (c) itdating.site</div>
        </footer>
    )
}

export default Footer;
