import "./TinfoffPaymentForm.scss";
import {forwardRef, useEffect, useState} from "react";



const TinkoffPaymentForm = forwardRef(
    function TinkoffPaymentForm({
                                    formData,
                                    seed = Number((Math.random() * 10000).toFixed(0)),
                                }, submitRef) {

        const url = import.meta.env.VITE_TINKOFF_PAYMENT_URL;
        const terminalKey = import.meta.env.VITE_TINKOFF_TERMINAL_KEY;
        const frame = "false";
        const language = 'ru';

        /**
         * Платежный виджет с чеком
         */
        const addFormEventListener = () => {
            const TPF = document.getElementById("payform-tinkoff-" + seed);
            const taxation = 'usn_income';

            TPF.addEventListener("submit", function (e) {
                e.preventDefault();
                const {description, amount, email, phone, receipt} = TPF;

                if (receipt) {
                    if (!email.value && !phone.value)
                        return alert("Поле E-mail или Phone не должно быть пустым");

                    TPF.receipt.value = JSON.stringify({
                        "EmailCompany": import.meta.env.VITE_TINKOFF_MERCHANT_EMAIL,
                        "Taxation": taxation,
                        "FfdVersion": "1.2",
                        "Items": [
                            {
                                "Name": description?.value || "Оплата",
                                "Price": amount?.value * 100,
                                "Quantity": 1.00,
                                "Amount": amount?.value * 100,
                                "PaymentMethod": "full_prepayment",
                                "PaymentObject": "service",
                                "Tax": "none",
                                "MeasurementUnit": "pc"
                            }
                        ]
                    });
                }
                pay(TPF);
            });
            //TPF.submit();
        }

        const addTinkoffScript = () => {
            const script = document.createElement('script');
            script.src = url;
            script.async = false;
            document.body.appendChild(script);
            return script;
        }


        useEffect(() => {
            const tinkoffScript = addTinkoffScript();
            addFormEventListener();

            return () => {
                document.body.removeChild(tinkoffScript);
            }
        }, []);

        return (
            <>
                <form className="payform-tinkoff" name={"payform-tinkoff-" + seed} id={"payform-tinkoff-" + seed}
                        style={{display: "none"}}
                >
                    <input className="payform-tinkoff-row" type="hidden" name="terminalkey" value={terminalKey}/>
                    <input className="payform-tinkoff-row" type="hidden" name="frame" value={frame}/>
                    <input className="payform-tinkoff-row" type="hidden" name="language" value={language}/>
                    <input className="payform-tinkoff-row" type="hidden" name="receipt" value=""/>
                    <input className="payform-tinkoff-row" type="text" placeholder="Сумма заказа" name="amount" required
                           value={formData.amount}/>
                    <input className="payform-tinkoff-row" type="hidden" placeholder="Номер заказа" name="order"
                           value={formData.id}/>
                    <input className="payform-tinkoff-row" type="text" placeholder="Описание заказа" name="description"
                           value={formData.description}/>
                    <input className="payform-tinkoff-row" type="hidden" placeholder="ФИО плательщика" name="name"/>
                    <input className="payform-tinkoff-row" type="email" placeholder="E-mail" name="email"
                           value={formData.email}/>
                    <input className="payform-tinkoff-row" type="hidden" placeholder="Контактный телефон" name="phone"/>
                    <input className="payform-tinkoff-row payform-tinkoff-btn" type="submit" value="Оплатить" ref={submitRef}/>
                </form>
            </>
        )
    });

export default TinkoffPaymentForm;
