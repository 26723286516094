import {NavLink, useLocation} from 'react-router-dom';

import './Header.scss';
import {useEffect, useRef, useState} from "react";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import PremiumModal from "../Modal/PremiumModal/PremiumModal.jsx";
import ProfileMenu from "./ProfileMenu.jsx";
import useWindowDimensions from "../../hooks/useWindowDimensions.js";

function Header({isLogIn, showFilters, onFilterClick}) {

    const [isOpen, setIsopen] = useState(false);
    const { height, width } = useWindowDimensions();
    const menuRef = useRef();
    const mobileMenuRef = useRef(null);
    const location = useLocation().pathname;


    const showModal = () => setIsopen((prev) => !prev);



    const toast = useRef(null);
    const [visible, setVisible] = useState(false);


    const onPremiumDialogHide = () => {
       setVisible(false)
    }

    const setPremiumVisible = () => {
        setVisible(true);
    }

    const handleMobileMenuClick = () => {
        console.log('mobile menu clicked');
        const active = 'active';
        /*if (!mobileMenuRef.current.classList.contains(active)) {
            mobileMenuRef.current.classList.add(active);
        } else {
            mobileMenuRef.current.classList.remove(active);
        }*/
        if (menuRef.current.style.display === 'none' || menuRef.current.style.display === '') {
            menuRef.current.style.display = 'flex';
        } else {
            menuRef.current.style.display = 'none';
        }
    }

    const handleMobileMenuCloseClick = () => {
       // mobileMenuRef.current.classList.remove('active');
        if (width <= 1010)
            menuRef.current.style.display = 'none';
    }

    useEffect(() => {
        if (!location.includes('/homepage')) {
            //mobileMenuRef.current.style.display = 'none';
        }
    }, [location]);

    return (
        <header className="header">
            <div className="header__container container">

                <a href="/">
                    <img src="/storage/static/logo3.png" className="header__logo"></img>
                </a>

                <div className="header__mobile-wrapper neonText">

                    {/*<a href="/">ItDating</a> */}
                    <div className="header__mobile-icons">
                        <NavLink to="/homepage/users"><img src="/storage/icons/search.png"></img></NavLink>
                        <NavLink to="/homepage/blog"><img src="/storage/icons/blog-pencil.png"></img></NavLink>
                        <NavLink to="/homepage/messenger"><img src="/storage/icons/comment-alt.png"></img></NavLink>
                        <NavLink to="/homepage/premium"><img src="/storage/icons/crown-header.png"></img></NavLink>
                    </div>

                    <NavLink to="/homepage/me">
                        <img src="/storage/icons/profile.png" className="header__mobile-profile"></img>
                    </NavLink>
                    {/*<div  ref={mobileMenuRef}  className="header__mobile-menu" onClick={handleMobileMenuClick}>
                        <span></span>
                    </div> */}
                </div>

                <div className="header__menu">
                    <ul ref={menuRef} className="menu">
                        {isLogIn ?
                            (<>
                                <div className="header__mobile-menu active" onClick={handleMobileMenuCloseClick}>
                                    <span></span>
                                </div>

                                <li className="menu__item">
                                    <NavLink
                                        className='menu__nav'
                                        to='/homepage/users'
                                        onClick={handleMobileMenuCloseClick}
                                    >Главная</NavLink>
                                </li>
                                <li className="menu__item">
                                    <NavLink
                                        className='menu__nav'
                                        to='/homepage/me'
                                        onClick={handleMobileMenuCloseClick}
                                    >Моя анкета</NavLink>
                                </li>

                            </>) :
                            (<></>)
                        }
                        <li className="menu__item">
                            <NavLink
                                className='menu__nav'
                                to='/homepage/blog'
                            >Блог</NavLink>
                        </li>

                        {!isLogIn ?
                            <>
                                <li className="menu__item">
                                    <NavLink
                                        className='menu__nav'
                                        to='/homepage/contacts'
                                    >Контакты</NavLink>
                                </li>

                                <li className="menu__item">
                                    <NavLink
                                        className='menu__nav'
                                        to='/homepage/about'
                                    >О нас</NavLink>
                                </li>
                                <li className="menu__item">
                                    <NavLink
                                        className='menu__nav'
                                        to='/login'
                                    >Регистрация / Вход</NavLink>
                                </li>
                            </> :
                            <></>
                        }


                        <li className={!isLogIn ? "menu__item menu__item-landing" : "menu__item"}>
                            <NavLink
                                className='menu__nav'
                                to='/homepage/premium'
                            >
                                <img src="/storage/icons/crown.png"/>
                                Премиум</NavLink>
                        </li>

                        {isLogIn ?
                            <Button
                                className={!showFilters ? "menu__filter-toggle search" : "menu__filter-toggle close"}
                                rounded severity="success" aria-label="Search"
                                onClick={() => {
                                    onFilterClick();
                                }}/>
                            : <></>
                        }
                    </ul>
                </div>
                {isLogIn ?
                    <div className="header__user-control">
                        <ul className="user-control">
                            <ProfileMenu setPremiumVisible={setPremiumVisible}/>
                            <Toast ref={toast}/>
                            <PremiumModal visible={visible} onPremiumDialogHide={onPremiumDialogHide}/>

                        </ul>
                    </div>
                    :
                    <></>}
            </div>
        </header>
    )
}

export default Header;
