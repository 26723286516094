import React, {useEffect, useRef, useState} from "react";
import "../Menu.scss";
import {Image} from "primereact/image";
import {ContextMenu} from "primereact/contextmenu";
import {useToastContext} from "../../../context/ToastContext.jsx";
import {copyToClipboard, downloadFile} from "../utils.js";
import {stripHtml} from "../../../assets/utility.js";

const ChatContextMenu = ({ data, callback }) => {

    const [imageDimensions, setImageDimensions] = useState(null);
    const imageRef = useRef(null);

    const [ref, setRef] = useState();
    const toastRef = useToastContext();

    useEffect(() => {
        setRef(toastRef);
    }, [toastRef]);


    const stickerCmRef = useRef(null);
    const imageCmRef = useRef(null);
    const textCmRef = useRef(null);

    const showToast = (header = 'Info', content) => {
        ref?.current?.show({
            severity: 'info',
            summary: header,
            detail: content,
            sticky: true,
        });
    };

    const textContextMenuItems = [
        {
            label: 'Скопировать текст',
            command: () => {
                const info = data[0].title;
                copyToClipboard(info);
                showToast('Скопировано', info);
            }
        },
        {
            label: 'Ответить' ,
            command: () => {
                callback(data[0]);
            }
        }
    ];

    const stickerContextMenuItems = [
        {
            label: 'Загрузить',
            command: () => {
                const path = data[0].sticker;
                downloadFile(path);
                showToast('Файл загружен', path.split('/').at(-1));
            }
        }
    ]

    const imageContextMenuItems = [
        {
            label: 'Загрузить',
            command: () => {
                const path = data[0].file.path;
                downloadFile(path);
                showToast('Файл загружен', path.split('/').at(-1));
            }
        }
    ]


    useEffect(() => {
        const img = imageRef.current;

        if (img !== null && imageDimensions !== null) {
            console.log('img: ' + JSON.stringify(imageRef.current.width));
            const baseWidth = 400;
            const minBaseWidth = Math.min(imageDimensions.width, baseWidth);
            img.style.setProperty('width', minBaseWidth, 'important');
            img.style.setProperty('height', minBaseWidth / imageDimensions.width * imageDimensions.height, 'important');
        }

    }, [imageDimensions]);


    return (
            data.map((item) => (
                <>
                    <ContextMenu key={Math.random()} model={stickerContextMenuItems} ref={stickerCmRef} breakpoint="767px" />
                    <ContextMenu key={Math.random()} model={imageContextMenuItems} ref={imageCmRef} breakpoint="767px" />
                    <ContextMenu key={Math.random()} model={textContextMenuItems} ref={textCmRef} breakpoint="767px" />


                    {item.sticker !== null ?
                        (
                            <Image key={Math.random()} className="img-sticker" src={item.sticker} alt="Image" preview
                                   imageStyle={{objectFit: "cover"}}
                                   onContextMenu={(e) => {
                                       //console.log("Right Click", e.pageX, e.pageY);
                                       stickerCmRef.current.show(e);
                                   }}
                            />
                        ) :
                        item.file !== null  && item.file !== undefined ? item.file.mime_type.startsWith('image') ? (
                                <>
                                    {/*<img className="menu_context-container__image" src={file.path} onLoad={() => getImageDimensions(file.path)} ref={imageRef}></img>*/}
                                    <Image key={Math.random()} className="menu_context-container__image" src={item.file.path} alt="Image"
                                           preview
                                           imageStyle={{objectFit: "cover"}}
                                           onContextMenu={(e) => {
                                               imageCmRef.current.show(e);
                                           }}
                                    />
                                </>
                            ): item.file.mime_type.includes('octet-stream') || item.file.mime_type.startsWith('video') ? (
                                    <video key={Math.random()} width="320" height="240" controls preload="none"
                                           onContextMenu={(e) => {
                                        imageCmRef.current.show(e);
                                    }}>
                                        <source src={item.file.path} type="video/mp4"></source>
                                        <source src={item.file.path} type="video/mov"></source>
                                    </video>


                                ) :
                                (
                                    <p className={"menu_context-container " + item.style}
                                       key={item.key}
                                       onClick={() => {downloadFile(item.file.path)}}>

                                            <div className="menu_context-container__attach">
                                            </div>
                                            <div className="menu_context-container__attachinfo">
                                                <a href={item.file.path}>{item.file.path.split('/').at(-1)}</a>
                                                <div>{Number((item.file.size / 1024).toFixed(1))} KB</div>
                                            </div>
                                    </p>
                                )
                            : (
                                <>
                                    <p className={"menu_context-container " + item.style}
                                       key={item.key}
                                       onContextMenu={(e) => {
                                        textCmRef.current.show(e);
                                    }}>
                                        {item.quote !== null && item.quote !== undefined ? (<>
                                            <div className="menu_context-container__quote">{item.quote.content}</div>
                                            <div className="break"></div>
                                        </>) : (<></>)}
                                        {stripHtml(item.title)}

                                        <span>{item.span}</span>
                                    </p>
                                </>)}
                </>)
            )

    );
};
export default ChatContextMenu;
