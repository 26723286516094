export const isTagExists = (tags, name) => {
    let result = false;
    tags.forEach((tag) => {
        console.log(tag.value);
        if (tag.value.toLowerCase() === name.toLowerCase()) {
            result = true;
            return result;
        }
    });
    return result;
}