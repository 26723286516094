import axios from "axios";
import {APPLICATION_JSON_HEADER, FORM_DATA_HEADER} from "../assets/headers.js";

export async function fetchCreatePayment(data) {
    let result = {
        ok: false,
        payment: {}
    }

    try {
        const response = await axios({
            method: 'post',
            url: '/payment/create',
            headers: APPLICATION_JSON_HEADER,
            data: data,
        });

        console.log(response);

        result = {
            ...result,
            ok: true,
            payment: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            message: 'Ошибка получения данных',
        }
    }
    finally {
        return result;
    }
}

export async function fetchPaymentCallbackTest(OrderId) {
    let result = {
        ok: false,
        payment: {}
    }

    try {
        const response = await axios({
            method: 'post',
            url: '/payment/callback',
            headers: FORM_DATA_HEADER,
            data: {
                OrderId: OrderId,
            },
        });

        console.log(response);

        result = {
            ...result,
            ok: true,
            payment: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            message: 'Ошибка получения данных',
        }
    }
    finally {
        return result;
    }
}