import './Main.scss';

import Athentication from '../Athentication/Athentication';
import {useParams} from "react-router-dom";
import {useEffect} from "react";

function Main(props) {

    return (
        <main className="main">
            <div className="container">
                <div className="main__row main__top">
                    <div className="main__authentication">
                        <Athentication {...props} />
                    </div>
                    <div className="main__slogan">
                        <h2 className="slogan">Знакомься, чтобы найти людей</h2>
                    </div>
                </div>
                <div className="main__row main__description">
                    <div className="description">
                        <h2 className="description__title">Описание сервиса</h2>
                        <p className="description__text">Наш сайт - площадка для знакомств it-специалистов и работников
                            смежных профессий.</p>
                        <p className="description__text">Мы, айтишники - самые разнообразные люди. Разработчики, дизайнеры, менеджеры проектов.</p>
                        <p className="description__text">Зачастую нам сложно встретить свою половинку среди тех, кто "не в теме".</p>
                        <p className="description__text">Это сайт для всех нас. Уютная атмосфера, никаких посторонних и только люди!</p>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Main;
