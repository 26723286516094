import {APPLICATION_JSON_HEADER} from "../assets/headers.js";
import {Status} from "./status.js";

export async function fetchLikes(userId) {
    let result = {
        ok: false,
        likes: [],
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/likes/' + userId,
            headers: APPLICATION_JSON_HEADER,
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            likes: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            likes: e.message,
        }
    }
    finally {
        return result
    }
}

export async function fetchAddLike(data) {
    let result = {
        ok: false,
        message: '',
    }

    try {
        const response = await axios({
            method: 'post',
            url: '/likes',
            headers: APPLICATION_JSON_HEADER,
            data: data
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            message: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            message: e.message,
        }
    }
    finally {
        return result
    }
}