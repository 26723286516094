import "./About.scss";
import {Image} from "primereact/image";

function About() {
    return (
        <div className="about__main">
            <h1>О нашем сервисе</h1>
            <p>Itdating сайт – первая онлайн-платформа для знакомств «айтишников». Тысячи одиноких девушек и парней найдут здесь людей, друзей и любовь! Знакомства для айтишников Уютная атмосфера, никаких посторонних и только люди!</p>
            <Image src="/storage/static/pair2.jpg" />
        </div>
    )
}

export default About;
