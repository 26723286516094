import {Dialog} from "primereact/dialog";
import {Card} from "primereact/card";
import './PremiumModal.scss';
import {Button} from "primereact/button";
import PremiumContent from "../../Premium/PremiumContent.jsx";

function PremiumModal ({visible = false, onPremiumDialogHide}) {




    return (
        <Dialog header="Выберите свой тарифный план" visible={visible} style={{width: '80vw'}}
                onHide={onPremiumDialogHide}>
            <PremiumContent />
        </Dialog>
    )
}

export default PremiumModal;