import {APPLICATION_JSON_HEADER} from "../assets/headers.js";
import {Status} from "./status.js";

export async function fetchMedia(userId) {
    let result = {
        ok: false,
        media: [],
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/media/' + userId,
            headers: APPLICATION_JSON_HEADER,
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            media: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            media: e.message,
        }
    }
    finally {
        return result
    }
}

export async function fetchDeleteMedia(mediaId) {
    let result = {
        ok: false,
        media: [],
    }

    try {
        const response = await axios({
            method: 'delete',
            url: '/delete-media/' + mediaId,
            headers: APPLICATION_JSON_HEADER,
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            media: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            media: e.message,
        }
    }
    finally {
        return result
    }
}

export async function fetchMarkMedia(data) {
    let result = {
        ok: false,
        media: [],
    }

    try {
        const response = await axios({
            method: 'post',
            url: '/media/mark',
            headers: APPLICATION_JSON_HEADER,
            data: data,
        });

        //console.log('response: ' + JSON.stringify(response));
        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            media: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            media: e.message,
        }
    }
    finally {
        return result
    }
}