import React from "react";


const modal = {
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "50%",
    height: "600px",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.8)"
};

const centerModal = {
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "50%",
    height: "600px",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.8)"
};

const close = {
    position: "absolute",
    top: 15,
    right: 35,
    color: "#f1f1f1",
    fontSize: 40,
    fontWeight: "bold",
    cursor: "pointer"
};

const modalContent = {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    width: "85%",
    height: "100%",
    margin: "auto"
};


export const MyModal = ({ onOpen, children }) => {
    return <div onClick={onOpen} className="profile__mymodal"> {children}</div>;
};

export const ModalContent = ({ onClose, children }) => {
    return (
        <div style={centerModal}>
      <span style={close} onClick={onClose}>
        &times;
      </span>
            <div style={modalContent}>{children}</div>
        </div>
    );
};

export const CenterModalContent = ({ onClose, children }) => {
    return (
        <div style={modal}>
      <span style={close} onClick={onClose}>
        &times;
      </span>
            <div style={modalContent}>{children}</div>
        </div>
    );
};

export const CustomModalContent = ({ className, onClose, children, top, left, width, height, background}) => {
    return (
        <div className={className} style={{...modal, ...{
            top: top,
                left: left,
                width: width,
                height: height,
                position: "absolute",
                background: background,
                borderRadius: "5px",
            }
        }}>
      <span style={close} onClick={onClose}>
        &times;
      </span>
            <div style={{...modalContent, ...{flexWrap: "no-wrap", flexDirection: "column"}}}>{children}</div>
        </div>
    );
}
