import PrefsSelect from "../../UI/PrefsSelect/PrefsSelect.jsx";
import {getBodyPrefs} from "../../../assets/utility.js";
import PrefEnums from "../PrefEnums.jsx";
import React from "react";

function ProfileLookCard ({user, info, prefs, handleChangeSelect, handleChange}) {

    const lookFeatures = {
        piercing: 'Пирсинг',
        scars: 'Шрамы',
        tatoos: 'Татуировки',
        tunnels: 'Туннели',
        look_other: 'Другое',
    }

    return (
        <div className="profile__col-wrapper">
            <div className="profile__subtitle look">
                Внешность
            </div>

            <div className="profile__col col-look">
                <ul className="profile__info profile-list">
                        <li className="profile-list__item">
                            <PrefsSelect
                                label='Телосложение'
                                name='body_type'
                                prefs={getBodyPrefs(user)}
                                value={info.body_type}
                                onChange={handleChangeSelect}
                            />
                        </li>
                        <li className="profile-list__item">
                            <PrefsSelect
                                label='Цвет глаз'
                                name='eye_color'
                                prefs={prefs.eye_color}
                                value={info.eye_color}
                                onChange={handleChangeSelect}
                            />
                        </li>
                        <li className="profile-list__item">
                            <PrefsSelect
                                label='Цвет волос'
                                name='hair_color'
                                prefs={prefs.hair_color}
                                value={info.hair_color}
                                onChange={handleChangeSelect}
                            />
                        </li>
                        <li className="profile-list__item">
                            Особенности:
                            <div className="profile-list__features">
                                <PrefEnums
                                    info={info}
                                    onChange={handleChange}
                                    features={lookFeatures}
                                />
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
    )
}

export default ProfileLookCard;