import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeFilter, clearFilter } from '../../store/reducers/FilterSlice';

import './Filter.scss';
import {Slider} from "primereact/slider";
import FilterLocation from "./FilterLocation.jsx";
import {Button} from "primereact/button";
import {fetchTags} from "../../fetchingAPI/tags.js";
import {MultiSelect} from "primereact/multiselect";
import {SelectButton} from "primereact/selectbutton";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions.js";

const renderOptions = (from, to) => {
    const elems = [];

    for (let i = from; i <= to; i++) {
        elems.push(<option key={i} value={i} >{i}</option>)
    }

    return elems;
}


function Filter() {
    const { name, gender, city, country, age_min, age_max } = useSelector(state => state.filterReducer);
    const dispatch = useDispatch();
    const { height, width } = useWindowDimensions();
    const [show, setShow] = useState(true);

    const navigate = useNavigate();

    const minAge = 18;
    const maxAge = 70;

    const [age, setAge] = useState([minAge, maxAge]);

    const [tags, setTags] = useState(null);

    const [selectedTags, setSelectedTags] = useState(null);

    const options = ['мужчина', 'женщина'];




    const changeFilterHandler = (evt) => {
        if (typeof evt.preventDefault == 'function')
            evt.preventDefault();

        const { name, value } = evt.target;

        dispatch(changeFilter({
            key: name,
            value: value,
        }));

        navigate('/homepage/users');
    }

    const onAgeChange = (e) => {
        setAge(e.value);
        if (e.value.at(0) !== minAge) {
            e.target = {
                name: 'age_min',
                value: e.value.at(0),
            }
        } else {
            e.target = {
                name: 'age_max',
                value: e.value.at(-1),
            }
        }

        changeFilterHandler(e);
    }

    const onTagsChange = (e) => {
        setSelectedTags(e.value);
        e.target = {
            name: 'tags',
            value: e.value,
        }
        changeFilterHandler(e);
    }

    const [numShowedTags, setNumShowedTags] = useState(3);

    const renderTags = () => {

    }

    const addShowedTags = () => {
        const numAdd = tags.length - numShowedTags;

        setNumShowedTags(numShowedTags + numAdd < 5 ? numAdd : 5);
    }

    const getTags = async () => {
        const result = await fetchTags();
        let data = [];
        result?.tags?.forEach((tag) => {
            data.push(tag.value);
        });
        setTags(data);
    }

    useEffect(() => {
        getTags();
    }, []);


    useEffect(() => {
        if (width < 1000) {
            setShow(false);
        } else {
            setShow(true);
        }
    }, [width]);

    const handleClear = (evt) => {
        evt.preventDefault();

        dispatch(clearFilter());
    }

    const defaultValue = "";


    return (
        <div className="filter animated fadeInUp">
            <div className="container filter__container">
                <span className="filter__hint">Я ищу: </span>
                <img className="filter__image"
                     src="/storage/icons/filters.png"
                     onClick={() => {setShow((prev) => !prev)}}
                >

                </img>

                {/*
                <input
                    className='filter__search filter__input'
                    name='name'
                    value={name}
                    placeholder='Введите имя'
                    onChange={changeFilterHandler}
                />

                */}

                {show ?
                    <>

                        <div className="filter__gender">
                            {/*

                            <select
                                name="gender"
                                className="filter__input"
                                value={gender}
                                onChange={changeFilterHandler}
                            >
                                <option value="">Любого пола</option>
                                <option value="мужчина">Мужчину</option>
                                <option value="женщина">Женщину</option>
                            </select>
                            */}

                            <SelectButton value={gender} onChange={(e) => {
                                //setValue(e.value);
                                console.log('value: ' + e.value.replace('ну', 'нa'));
                                const value = e.value.replace('ну', 'нa');
                                e.target = {
                                    name: 'gender',
                                    value: e.value,
                                };
                                changeFilterHandler(e);
                            }} options={options}/>
                        </div>
                        <div className="filter__age">
                            <span className="filter__hint">Возраст от {age[0]} до {age.at(-1)}</span>
                            <Slider value={age} onChange={(e) => onAgeChange(e)} className="w-14rem" range/>
                            {/*
                            <span className="filter__hint">Возрастом от
                                <select
                                    className='filter__age-select'
                                    name='age_min'
                                    value={age_min}
                                    onChange={changeFilterHandler}
                                >
                                    {renderOptions(minAge, age_max)}
                                </select>
                                до
                                <select
                                    className='filter__age-select'
                                    name='age_max'
                                    value={age_max}
                                    onChange={changeFilterHandler}
                                >
                                    {renderOptions(age_min, maxAge)}
                                </select>
                            </span>
                            <select
                                name="age_min"
                                className="filter__input"
                                value={gender}
                                onChange={changeFilterHandler}
                            >
                                <option value="">Любого пола</option>
                                <option value="мужчина">Мужчину</option>
                                <option value="женщина">Женщину</option>
                            </select>
                            */}

                        </div>

                        <FilterLocation city={city} country={country} changeFilterHandler={changeFilterHandler}/>

                        <div className="filter__tags tags">
                            <span className="filter__hint">Теги:</span>
                            <div className="tags__items">
                                {/*<button className="tags__tag">+</button>*/}
                                {tags !== null ?
                                    (
                                        <MultiSelect value={selectedTags} onChange={(e) => onTagsChange(e)} options={tags}
                                                     optionLabel=""
                                                     placeholder="Выберите теги" maxSelectedLabels={3}
                                                     className="w-full md:w-20rem"/>
                                    )
                                    : (
                                        <></>
                                    )}
                                {/* {numShowedTags < tags.length
                                    && <button
                                        className="tags__tag"
                                        onClick={addShowedTags}
                                    >еще {tags.length - numShowedTags}..</button>
                                } */}

                            </div>
                        </div>
                        <Button type="button" label="Найти" severity="success" raised onClick={() => {
                            navigate('/homepage/users');
                        }}/>
                        <Button type="button" label="Очистить" severity="warning" raised onClick={handleClear}/>
                        {/*
                        <button
                            className="filter__opn-all filter__input"
                            onClick={handleClear}
                        >Очистить</button>
                        */}
                        {/*
                        <button
                            className="filter__opn-all filter__input"
                        >Все фильтры</button>
                        */}
                    </>
                    : <></> }
            </div>
        </div>
    );
};


export default Filter;
