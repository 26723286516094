import React, { createContext, createRef, useContext } from "react";
import {Toast} from "primereact/toast";

const ToastContext = createContext();
const toastRef = createRef();

export const ToastContextProvider = (props) => {
    return (
        <ToastContext.Provider value={toastRef}>
            {props.children}
        </ToastContext.Provider>
    );
};

export const useToastContext = () => useContext(ToastContext);

