import React from "react";

function LifestyleCard ({info, notFilledShortMessage}) {
    return (
        <div className="profile__col">
            <div className="profile__subtitle">
                Lifestyle
            </div>
            <ul className="profile__info profile-list">
                <li className="profile-list__item">
                    {/*<ProfileItem
                                                info={info}
                                                name={'food_prefs'}
                                                title={'Отношение к мясу'}
                                            />
                                            */}
                    Отношение к мясу: {info.food_prefs === "" ? notFilledShortMessage : info.food_prefs}
                </li>
                <li className="profile-list__item">
                    Отношение к алкоголю: {info.alco_prefs === "" ? notFilledShortMessage : info.aclo_prefs}
                </li>
                <li className="profile-list__item">
                    {/*<ProfileItem
                                                info={info}
                                                name={'smoking'}
                                                title={'Курение'}
                                            />*/}
                    Курение: {info.smoking === 0 ? 'не курю' : 'курю'}
                </li>
            </ul>
        </div>
    )
}

export default LifestyleCard;