import {
    FacebookIcon,
    FacebookShareButton, InstapaperIcon, InstapaperShareButton, OKIcon, OKShareButton, RedditIcon, RedditShareButton,
    TelegramIcon,
    TelegramShareButton, TwitterIcon, TwitterShareButton, VKIcon, VKShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import React from "react";
import "./ProfileShare..scss";

function ProfileShare ({
		       user = null,
}) {

    const currentPageUrl = user !== null ? window.location.origin + '/homepage/users/' + user?.id : window.location.href;

    const shareButtonStyle = {
        marginRight: "10px",
    };

    const iconSize = 40;

    return (
        <div className="profile__header-share">
            <h5>Поделиться в соцсетях:</h5>
            <FacebookShareButton url={currentPageUrl} style={shareButtonStyle}>
                <FacebookIcon size={iconSize}/>
            </FacebookShareButton>

            <TwitterShareButton url={currentPageUrl} style={shareButtonStyle}>
                <TwitterIcon size={iconSize}/>
            </TwitterShareButton>

            <RedditShareButton url={currentPageUrl} style={shareButtonStyle}>
                <RedditIcon size={iconSize} />
            </RedditShareButton>


            <TelegramShareButton url={currentPageUrl} style={shareButtonStyle}>
                <TelegramIcon size={iconSize}/>
            </TelegramShareButton>
        </div>
    )
}

export default ProfileShare;
