 export function objectToFormData(object) {
    const formData = new FormData();

    for (let key in object) {
        formData.append(key, object[key]);
    }

    return formData;
}

let timerId = null;

export function showErrorMessage(msg, callback) {
    if (timerId) {
        clearTimeout(timerId);
        timerId = null;
        callback(null);
    }
    callback(msg);

    setTimeout(() => {
        callback(null);
        clearTimeout(timerId);
        timerId = null;
    }, 2000);
}

export function convertGenderRuToEn (gender) {
    switch (gender) {
        case 'мужчина':
            return 'man';
        case 'женщина':
            return 'woman';
        default:
            return null;
    }
}

export function getBodyPrefs (user) {
    const { prefs, info } = user;
    if (!user.info || !user.prefs) {
        return null;
    }
    const prefSuff = '_body_type';
    const genderRu = info.gender;
    // const genderRu = 'женщина';
    // const genderRu = '';

    const genderEn = convertGenderRuToEn(genderRu);

    if (genderEn) {
        return prefs[genderEn + prefSuff]
    }

    return Array.from( new Set([ ...prefs.man_body_type, ...prefs.woman_body_type ]));
}

/**
 * Обертывает принимаемую функцию в декоратор,
 * отсрочивающий вызов принимаемой функции
 * на заданное время
 * Каждый последующий вызов полученной функции
 * обнуляет таймер
 *
 * @param {декорируемая функция} func
 * @param {задержка, мс} ms
 * @returns исходная функция, обернутая в отсрочивающий декоратор
 */
export function debounce(func, ms) {
    let timeout;
    return function() {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            func.apply(this, arguments);
            clearTimeout(timeout);
        }, ms);
    }
}

 /**
  * Вывод/форматирование даты/времени онлайн
  * @param user
  * @returns {string}
  */

 export const getDateString = (user) => {
    return getDateStringFromPlain(user.last_active_at);
 }

 export const getDateStringFromPlain = (dateTime) => {
     const lastActive = new Date(dateTime);
     const now = new Date(Date.now());
     const difference = Math.abs(lastActive.getTime() - now.getTime());
     const dayDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
     const lastActiveDateTime = lastActive.getHours() + ':' + (lastActive.getMinutes() < 10 ? '0' : '') + lastActive.getMinutes();
     let day = lastActive.getDate() + '.' + (lastActive.getMonth() < 10 ? '0' : '') + lastActive.getMonth();
     if (dayDifference < 1) {
         day = ' сегодня';
     }
     else if  (dayDifference < 2) {
         day = 'вчера';
     }
     return day + ' в ' + lastActiveDateTime;
 }

 /**
  * Проверка, онлайн ли пользователь
  * @param user
  * @returns {boolean}
  */
 export const checkOnline = (user) => {
     if (!user.last_active_at) {
         return false;
     }
     const lastActive = new Date(user.last_active_at);
     const now = new Date(Date.now());
     const difference = lastActive.getTime() - now.getTime();
     const secondsDiff = Math.abs(Math.floor(difference / 1000));
     return secondsDiff < 60 * 5;
 }

 /**
  * Удаление HTML-тегов
  * @param html
  * @returns {string|string}
  */
 export function stripHtml(html)
 {
     let tmp = document.createElement("DIV");
     tmp.innerHTML = html;
     return tmp.textContent || tmp.innerText || "";
 }

 /**
  * Returns a random integer between min (inclusive) and max (inclusive).
  * The value is no lower than min (or the next integer greater than min
  * if min isn't an integer) and no greater than max (or the next integer
  * lower than max if max isn't an integer).
  * Using Math.round() will give you a non-uniform distribution!
  */
 export function getRandomInt(min, max) {
     min = Math.ceil(min);
     max = Math.floor(max);
     return Math.floor(Math.random() * (max - min + 1)) + min;
 }
