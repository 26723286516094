import PrefsSelect from "../../UI/PrefsSelect/PrefsSelect.jsx";
import React from "react";
import {Card} from "primereact/card";
import EditableInput from "../../UI/EditableInput/EditableInput.jsx";

function ProfileLifestyleCard ({info, prefs, handleChangeSelect, handleChange}) {
    return (
        <div className="profile__col-wrapper">
            
            <div className="profile__subtitle lifestyle">
                Lifestyle
            </div>

            <div className="profile__col">
                <ul className="profile__info profile-list">
                    <li className="profile-list__item">
                        <PrefsSelect
                            label='Отношение к мясу'
                            name='food_prefs'
                            prefs={prefs.food_prefs}
                            value={info.food_prefs}
                            onChange={handleChangeSelect}
                        />
                    </li>
                    <li className="profile-list__item">
                        <PrefsSelect
                            label='Алкоголь'
                            name='alco_prefs'
                            prefs={prefs.alco_prefs}
                            value={info.alco_prefs}
                            onChange={handleChangeSelect}
                        />
                    </li>
                    <li className="profile-list__item profile-list__smoking">
                        <PrefsSelect
                            label='Курение'
                            name='smoking'
                            prefs={['нет', 'да']}
                            value={info.smoking === 0 ? 'нет' : 'да'}
                            onChange={handleChangeSelect}
                        />
                    </li>
                </ul>
            </div>

            <Card title="Укажите, как любите проводить время:" className="profile__my-spend-time">
                <EditableInput
                    name='spend_time'
                    placeholder={info['spend_time'] !== '' ? info['spend_time'] : 'Укажите, как любите проводить время'}
                    onChange={handleChange}
                    info={info}
                />
            </Card>
        </div>
    )
}

export default ProfileLifestyleCard;