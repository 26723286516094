import {TabPanel, TabView} from "primereact/tabview";
import {Image} from "primereact/image";
import EditableInput from "../UI/EditableInput/EditableInput.jsx";
import {Card} from "primereact/card";
import React, {useEffect, useState} from "react";
import ProfileLookCard from "./Cards/ProfileLookCard.jsx";
import ProfileLifestyleCard from "./Cards/ProfileLifestyleCard.jsx";
import ProfileHobbyCard from "./Cards/ProfileHobbyCard.jsx";
import MyProfileQuestionnaire from "./Сontent/MyProfileQuestionnaire.jsx";
import {fetchUserGifts} from "../../fetchingAPI/gifts.js";
import {Avatar} from "primereact/avatar";
import {getDateStringFromPlain} from "../../assets/utility.js";
import {Divider} from "primereact/divider";
import ProfileBadgeCard from "./Cards/ProfileBadgeCard.jsx";
import "./MyProfileContent.scss"


function MyProfileContent ({user, info, prefs, handleChange, handleChangeSelect}) {

    const [gifts, setGifts] = useState(null);
    const getUserGifts = async() => {
        let result = await fetchUserGifts();
        //console.log('user gifts result: ' + JSON.stringify(result));
        setGifts(result.gifts);
    }


    useEffect(() => {
        getUserGifts();
    }, []);

    return (
        <div className="profile__row profile__row-content">


            <div className="card">
                <TabView style={{}} className="profile__tab">
                    <TabPanel header="Анкета">

                        <MyProfileQuestionnaire
                            user={user}
                            info={info}
                            prefs={prefs}
                            handleChange={handleChange}
                            handleChangeSelect={handleChangeSelect}
                        />

                    </TabPanel>
                    <TabPanel header="Подарки">
                        <h5 className="m-0 mb-3">
                            Здесь находятся все знаки внимания, оказанные вам другими пользователями.
                        </h5>

                        <Card title="Мои подарки" className="profile__gifts-card">
                            <Divider />
                            <div className="profile__gifts-wrapper">
                                {
                                    gifts !== null && gifts.map((gift) => {
                                        return (
                                            <div key={gift.id} className="profile__gifts-container">
                                                <Image src={gift.url} alt={gift.name} className="profile__gifts-image"/>
                                                <div className="profile__gifts-name">
                                                    {gift.name}
                                                </div>
                                                <div className="profile__gifts-sender">
                                                    <Avatar image={gift.sender.photo_url} shape="circle"
                                                            alt={gift.sender.name} className="profile__gifts-sender_image">
                                                    </Avatar>

                                                    <div className="profile__gifts-sender_info">
                                                        {gift.sender.name}
                                                        <div className='profile__gifts-sender-date'>
                                                            {getDateStringFromPlain(gift.created_at)}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Card>
                    </TabPanel>
                    <TabPanel header="Значки">

                        <h5 className="m-0 mb-3">
                            Здесь находятся все знаки отличия, полученные за время проведенное на портале.
                        </h5>
                        <ProfileBadgeCard user={user}/>

                    </TabPanel>
                </TabView>
            </div>

        </div>
    )
}

export default MyProfileContent;