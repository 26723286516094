import axios from "axios";
import {APPLICATION_JSON_HEADER} from "../assets/headers.js";
import {Status} from "./status.js";

export async function fetchPlans() {
    let result = {
        ok: false,
        plans: [],
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/plans',
            headers: APPLICATION_JSON_HEADER,
        });

        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            plans: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            plans: e.message,
        }
    }
    finally {
        return result
    }
}

export async function fetchSubscription(data) {
    let result = {
        ok: false,
        subscription: {},
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/subscription',
            headers: APPLICATION_JSON_HEADER,
        });

        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            subscription: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            subscription: e.message,
        }
    }
    finally {
        return result
    }
}

export async function fetchUserSubscription(userId) {
    let result = {
        ok: false,
        subscription: {},
    }

    try {
        const response = await axios({
            method: 'get',
            url: '/subscription/' + userId,
            headers: APPLICATION_JSON_HEADER,
        });

        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            subscription: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            subscription: e.message,
        }
    }
    finally {
        return result
    }
}

export async function fetchSubscribe(data) {
    let result = {
        ok: false,
        message: '',
    }

    try {
        const response = await axios({
            method: 'post',
            url: '/subscription',
            headers: APPLICATION_JSON_HEADER,
            data: data
        });

        result = {
            ...result,
            ok: response.data.status === Status.SUCCESS,
            message: response.data.result,
        }
    }
    catch(e) {
        result = {
            ...result,
            message: e.message,
        }
    }
    finally {
        return result
    }
}