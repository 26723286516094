import {Dialog} from "primereact/dialog";
import "./LoadmediaModal.scss"
import {useEffect, useState} from "react";
import {SelectButton} from "primereact/selectbutton";
import {Button} from "primereact/button";

function LoadMediaModal ({uploadedId, visible, onMediaDialogHide, onMediaDialogChoose}) {

    const options = ['public', 'private'];
    const [value, setValue] = useState(options[0]);

    useEffect(() => {
        console.log('uploadedId: ' + uploadedId);
    }, [uploadedId]);

    return (
        <Dialog className="load-media_modal" header="Управляйте доступом к вашей галерее" visible={visible}
                onHide={() => onMediaDialogHide()}>
            <div className="load-media_modal__image">
                <img src="/storage/reactions/private-folder.png"></img>
            </div>
            <p>Вы можете сделать загружаемые медиафайлы общедоступными, либо ограничить доступ для пользователей без подписки</p>
            <p>Выберите раздел: </p>

            <div className="load-media_modal__buttons">
                <SelectButton value={value} onChange={(e) => setValue(e.value)} options={options} />
                <Button label="Загрузить" severity="success" onClick={() => {onMediaDialogChoose(uploadedId, value)}}/>
            </div>

        </Dialog>
    )
}

export default LoadMediaModal;