import {useNavigate} from "react-router-dom";
import "./BlogCard.scss"
import {Card} from "primereact/card";
import {getDateStringFromPlain} from "../../assets/utility.js";
import {useEffect} from "react";

function BlogCard({post}) {
    const navigate = useNavigate();

    function truncate(str, n){
        return (str.length > n) ? str.slice(0, n-1) + '&hellip;' : str;
    };

    const imageUrl = "/storage/static/pictures-in-blog-post.jpeg";


    useEffect(() => {
        console.log();
    }, []);

    const excerptLength = 250;


    return (
        <Card>
            <div className="blog-card" onClick={() => {
                navigate('/homepage/blog/' + post.id);
            }}>
                <div className="blog-card__photo">
                    <img
                        key={Math.random()}
                        loading="lazy"
                        src={post.attachments.length > 0 ? post.attachments.at(-1).url : imageUrl}
                        alt="alt"
                        className='blog-card__img'

                    />
                </div>
                <div className="blog-card__info">
                    <div className="blog-card__title">
                        <h2>{post.title}</h2>
                    </div>
                    {/*<div className="blog-card__description">
                        {post.description}
                    </div>*/}
                    <div className="blog-card__body">
                        {truncate(post.body, excerptLength).replace('&hellip;', '')}
                    </div>
                    <div className="blog-card__bottom">
                        <span>{getDateStringFromPlain(post.created_at)}</span>
                        <span>{post?.author?.name}</span>
                    </div>

                </div>
            </div>
        </Card>

    )
}

export default BlogCard;
