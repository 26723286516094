import "./ProfileRibbonCard.scss";
import {Image} from "primereact/image";
import React, {useContext, useEffect, useState} from "react";
import {fetchDeleteMedia} from "../../../../fetchingAPI/media.js";
import {isFeatureExists, SubscriptionContext} from "../../../../context/SubscriptionContext.js";

function ProfileRibbonCard({className, media, likesByImage, handleLikeClick, handleDeleteClick, owner}) {

    const [likes, setLikes] = useState(0);

    const {subscription, setSubscription} = useContext(SubscriptionContext);
    const countLikes = (attachmentId) => {
        //console.log('attachmentId: ' + attachmentId);
        let result = 0;
        if (likesByImage !== null) {
            Object.keys(likesByImage).forEach((keyName, i) => {
                //console.log('lieksByImage: ' + JSON.stringify(likesByImage[keyName]));
                //console.log('keyName: ' + keyName + ' i: ' + i);
                if (keyName == attachmentId) {
                    //console.log('likesVByImage: ' + JSON.stringify(likesByImage[keyName]));
                    //console.log('len: ' + likesByImage[keyName].length);
                    result = likesByImage[keyName].length;
                    //return result;
                }
            });
        }

        return result;
    }


    useEffect(() => {
        let likes = countLikes(media.id);
        //console.log('likes: ' + likes);
        //console.log('owner: ' + owner);
        setLikes(likes);
    }, []);


    return ( owner !== undefined ?
        <>
            {media?.mime.startsWith('image') ?
                <Image className={className} src={media.url} alt="Image" width="150"
                       preview={owner || isFeatureExists(subscription,'photo-preview')} height="150"
                       pt={{image: {className: "media_" + media.id}}} imageStyle={{objectFit: "cover"}}
                /> :
                owner || isFeatureExists(subscription,'videos') ?
                    <video key={media.id} width="158" height="200" controls preload="metadata">
                        <source src={media.url + '#t=5.1'} type="video/mp4"></source>
                    </video> : <React.Fragment key={Math.random()} />
            }
            {media?.mime.startsWith('image') || owner || isFeatureExists(subscription,'video') ?
            <div className="image-buttons-wrapper">
                {
                    <div className="image-like-wrapper">
                        <img
                            className={(likes > 0 ? "image-liked" : "image-like") + " tooltip-owner-" + media.id}
                            src='/storage/icons/heart.png'
                            onClick={() => {
                                handleLikeClick(media)
                            }}
                        />
                        {likes}
                    </div>
                }

                {owner ?
                    <button className="toolbar-delete" onClick={() => {
                    handleDeleteClick(media.id)
                    }}></button>
                    :
                    <React.Fragment key={Math.random()} />
                }
            </div> : <React.Fragment key={Math.random()} />}
        </> : <React.Fragment key={Math.random()} />
    )
}

export default ProfileRibbonCard;
