import React, {useContext, useEffect, useRef, useState} from "react";
import "./ProfileGallery.scss"
import {fetchDeleteMedia, fetchMarkMedia, fetchMedia} from "../../../fetchingAPI/media.js";
import {FileUpload} from "primereact/fileupload";
import handleBeforeSend from "../../../utils/upload.js";
import {TabPanel, TabView} from "primereact/tabview";
import ProfileGalleryCard from "./Cards/ProfileGalleryCard.jsx"
import {Card} from "primereact/card";
import {fetchAddLike, fetchLikes} from "../../../fetchingAPI/likes.js";
import {Tooltip} from "primereact/tooltip";
import {sendNotify} from "../../../chatAPI/laravel-echo.js";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import LoadMediaModal from "../../Modal/LoadMediaModal.jsx";
import {isFeatureExists, SubscriptionContext} from "../../../context/SubscriptionContext.js";


function ProfileGallery({user, owner, handleGalleryHide}) {

    const [media, setMedia] = useState(null);
    const [publicMedia, setPublicMedia] = useState(null);
    const [privateMedia, setPrivateMedia] = useState(null);
    const [publicCount, setPublicCount] = useState(0);
    const [privateCount, setPrivateCount] = useState(0);
    const [privateAlbumSelected, setPrivateAlbumSelected] = useState(false);
    const fileUploadUrl = '/upload-media';

    const [likes, setLikes] = useState(null);
    const [likesByImage, setLikesByImage] = useState(null);

    const publicRef = useRef();
    const privateRef = useRef();
    const currentRef = null;
    const galleryRef = useRef();

    const [mediaModalVisible, setMediaModalVisible] = useState(false);
    const [uploadedId, setUploadedId] = useState(false);
    const {subscription, setSubscription} = useContext(SubscriptionContext);

    const getMedia = async() => {
        const result = await fetchMedia(user.id);
        //console.log('media result: ' + JSON.stringify(result));
        setMedia(result.media.original)
    }

    const getLikes = async() => {
        const result = await fetchLikes(user.id);
        //console.log('result: ' + JSON.stringify(result));
        setLikes(result.likes);
    }

    const sortLikesByImage = () => {
        let result = {};
        likes.forEach((like) => {
            let data = {
                senderId: like.sender_id,
                user: like.user,
                created_at: like.created_at,
                updated_at: like.updated_at,
            }
            if (result[like.attachment_id] === undefined) {
                result[like.attachment_id] = [data];
            } else {
                result[like.attachment_id].push(data);
            }

        });
        console.log('sort result: ' + JSON.stringify(result));
        setLikesByImage(result);
    }

    const setMediaCounters = () => {
        let publicCnt = 0;
        let privateCnt = 0;
        media.forEach((m) => {
            if (m.group == null || m.group == 'public') {
                publicCnt += 1;
            } else if (m.group == 'private'){
                privateCnt += 1;
            }
        });
        setPublicCount(publicCnt);
        setPrivateCount(privateCnt);
    }

    const fillPrivacyMedia = () => {
        let pubMedia = [];
        let privMedia = [];
        media.forEach((m) => {
            if (m.group == null || m.group == 'public') {
                pubMedia.push(m);
            } else if (m.group == 'private'){
                privMedia.push(m)
            }
        });
        setPublicMedia(pubMedia);
        setPrivateMedia(privMedia);

    }

    useEffect(() => {
        if (media !== null) {
            getLikes();
            setMediaCounters();
            fillPrivacyMedia();
        }

    }, [media]);


    useEffect(() => {
        if (likes !== null) {
            sortLikesByImage();
        }
    }, [likes]);


    /**
     * Обработчик завершения загрузки файла
     */
    const onUpload = (event) => {
        console.log('onUpload');
        const response = JSON.parse(event.xhr.responseText);
        console.log('uploaded id: ' + response.id);
        setUploadedId(response.id);
        setMediaModalVisible(true);
    }

    const addLike = async(data) => {
        const result = await fetchAddLike(data);
        if (result.message == 'created') {
            await sendNotify('notify_' + user?.id, 'like', data.attachmentId, data.url);
        }
        getLikes();
        sortLikesByImage();
        setMediaCounters();
        fillPrivacyMedia();
    }

    const handleLikeClicked = (media) => {
        if (!owner && isFeatureExists(subscription, 'likes')) {
            const data = {
                attachmentId: media.id,
                url: media.url,
            }
            addLike(data);
        }
    }

    const handleDeleteClick = async(mediaId) => {
        await fetchDeleteMedia(mediaId);
        await getMedia();
    }


    useEffect(() => {
        console.log('ProfileGallery user: ' + JSON.stringify(user));
        if (user !== undefined)
            getMedia();
    }, [user]);


    const onMediaDialogHide = () => {
        setMediaModalVisible(false);
    }

    const onMediaDialogChoose = async(uploadedId, privacy) => {
        onMediaDialogHide();
        const data = {
            userId: user.id,
            attachmentId: uploadedId,
            privacy: privacy,
        }
        const result = await fetchMarkMedia(data);
        console.log('result: ' + JSON.stringify(result));
        if (user !== undefined) {
            getMedia();
        }
    }



    const showImage = (media, width, height, isFirst = false) => (
        <>
            <ProfileGalleryCard
                owner={owner}
                media={media}
                width={width}
                height={height}
                isFirst={isFirst}
                likesByImage={likesByImage}
                handleDeleteClick={handleDeleteClick}
                handleLikeClick={handleLikeClicked}

            />

            {likesByImage !== null && Object.keys(likesByImage).length > 0 && likesByImage[media.id.toString()] !== undefined ?
               ( <Tooltip className="tooltip-like" target={".tooltip-owner-" + media.id} position="right bottom">
                    <p>{"Понравилось " + likesByImage[media.id.toString()].length}</p>
                    {
                        likesByImage[media.id.toString()].map((like) => (
                            <img src={like.user.photo_url}/>
                        ))
                    }

                </Tooltip>)
                : (<></>)
            }

        </>
    )

    const showPrivacyImage = (m, width, height, isFirst = false, privacy = 'public') => {
        console.log('m.url: ' + m.url + ' m.group: ' + m.group + ' privacy: ' + privacy);
        if (m.group === privacy || privacy === 'public' && m.group === null)
            return showImage(m, width, height, isFirst)
        else
            return null;
    }

    const showGalleryButtons = () => (
        <div className="flex align-items-center gap-2" style={{cursor: 'pointer', marginLeft: "32px", display: "flex"}}>

            <div className="profile-gallery__back" onClick={handleGalleryHide}></div>
            {owner ? ( <>
            <FileUpload
                mode="basic" name="file_name" url={fileUploadUrl} accept="image/*" maxFileSize={50000000}
                onUpload={onUpload} auto chooseLabel=" "
                chooseOptions={{icon: "chat-main__upload-icon"}} className="profile-gallery__add-photo"
                onBeforeSend={(event) => {
                    handleBeforeSend(event);
                }}
            />

            <FileUpload
                mode="basic" name="file_name" url={fileUploadUrl} accept="video/*" maxFileSize={50000000}
                onUpload={onUpload} auto chooseLabel=" "
                chooseOptions={{icon: "chat-main__upload-icon"}} className="profile-gallery__add-video"
                onBeforeSend={(event) => {
                    handleBeforeSend(event);
                }}
            />
                </>) : (<></>) }
        </div>
    )

    let privateSelected = false;

    const mediaMap = (m, media, index, privacy) => {
            if (index < 1 || index % 5 === 0) {
                return (
                    <>
                        <div className="profile-gallery__container">

                            {showPrivacyImage(m, "300px", "300px", false, privacy)}

                            {media[index + 1] !== undefined ? (
                                <div className="profile-gallery__column">
                                    {showPrivacyImage(media[index + 1], "200px", "200px", true, privacy)}

                                    {media[index + 2] !== undefined ?
                                        showPrivacyImage(media[index + 2], "200px", "200px", false, privacy)
                                        : (
                                            <></>
                                        )}
                                </div>
                            ) : (
                                <></>
                            )}

                            {media[index + 3] !== undefined ? (
                                <div className="profile-gallery__column">
                                    {showPrivacyImage(media[index + 3], "200px", "200px", true, privacy)}

                                    {media[index + 4] !== undefined ?
                                        showPrivacyImage(media[index + 4], "200px", "200px", false, privacy)
                                        : (
                                            <></>
                                        )}
                                </div>
                            ) : (
                                <></>
                            )}


                        </div>
                    </>
                )
            } else return (<></>)

    }


    /**
     * Рендер кнопки+счетчика лайков
     * @param i
     * @returns {Element}
     */

    const renderGalleryCustomControls = (i) => {
        const index = galleryRef.current?.getCurrentIndex();
        const attachmentId = media[index]?.id;

        /*
        console.log('attachmentId: ' + JSON.stringify(attachmentId));
        console.log('likesByImage[attachmentId]: ' + JSON.stringify(likesByImage[attachmentId]));
         */

        const likesCount = likesByImage[attachmentId] ? likesByImage[attachmentId].length : 0;
        return (
            <>
                <p className='image-gallery-custom-action' >
                    <div onClick={() => handleLikeClicked(media[index])}>
                        <img className={"tooltip-owner-" + media[index]?.id} src="/storage/icons/heart.png"/> {likesCount}
                    </div>
                    {likesByImage !== null && Object.keys(likesByImage).length > 0 && likesByImage[media[index]?.id.toString()] !== undefined ?
                        (<Tooltip className="tooltip-like" target={".tooltip-owner-" + media[index]?.id}
                                  position="right bottom">
                            <p>{"Понравилось " + likesByImage[media[index]?.id.toString()].length}</p>
                            {
                                likesByImage[media[index]?.id.toString()].map((like) => (
                                    <img src={like.user.photo_url}/>
                                ))
                            }

                        </Tooltip>)
                        : (<></>)
                    }
                    {owner ?
                        <p className="image-gallery-custom-action-next" onClick={() => handleDeleteClick(media[index]?.id)}>
                            <img src="/storage/icons/bin.png"/>
                        </p> :
                        <React.Fragment key={Math.random()} />
                    }
                </p>


            </>
        )
    }


    const showImages = (privacy = 'public') => {
        /*if (privacy === 'public' && publicMedia !== null) {
            return publicMedia.map((m, index) => mediaMap(m, publicMedia, index, privacy))
        } else if (privacy === 'private' && privateMedia !== null) {
            return privateMedia.map((m, index) => mediaMap(m, privateMedia, index, privacy))
        }

        else {
            return (<></>);
        }*/
       console.log('privacy: ' + privacy);
       let images = [];
       if (privacy === 'public' && publicMedia !== null && likesByImage !== null) {
            images = publicMedia.map((m, index) => {
                return {
                    original: m.url,
                    thumbnail: m.url,
                    originalHeight: 500,
                };
            })

       } else if (privacy === 'private' && privateMedia !== null) {
           images = privateMedia.map((m, index) => {
               return {
                   original: m.url,
                   thumbnail: m.url,
                   originalHeight: 500,
                   originalClass: 'private-image',
                   thumbnailClass: 'private-image',
               };
           })
        } else {
           return (<></>);
       }

       if (images.length > 0) {
           return (
               <>
                   <ImageGallery ref={galleryRef} items={images} showBullets renderCustomControls={renderGalleryCustomControls}
                                 useBrowserFullscreen={true}
                   />
                   <LoadMediaModal uploadedId={uploadedId} visible={mediaModalVisible} onMediaDialogHide={onMediaDialogHide}
                                   onMediaDialogChoose={onMediaDialogChoose}/>
               </>
           )
       } else {
           return (
               <div className="profile__gallery-no-images">
                   В альбоме пока нет медиафайлов. Чтобы загрузить изображение/видео, воспользуйтесь панелью выше.
               </div>
           )
       }


    }


    const handleTabChange = (e) => {
        console.log('e index: ' + e.index);
        if (e.index === 0) {
            setPrivateAlbumSelected(false);
        }
        else if (e.index === 1) {
            setPrivateAlbumSelected(true);
        }

    }



    return (
        <div className="profile-galery-new">


            <TabView style={
                {width: "98%"}
            }  onBeforeTabChange={handleTabChange}>


                <TabPanel header={"Общий (" + publicCount + ")"} ref={publicRef}>
                    {showImages()}
                </TabPanel>

                <TabPanel header={"Приватный (" + privateCount + ")"} ref={privateRef}>
                    {showImages("private")}
                </TabPanel>

                <TabPanel headerTemplate={showGalleryButtons} />


            </TabView>


            {/*<Card title="Альбомы">
                <p className="m-0">

                        privateAlbumSelected === true ?
                            'Приватный альбом - загруженные сюда фото и видео доступны только подписчикам' :
                            'Общий альбом - место, где пользователи могут выкладывать контент, видимый для всех.'

                </p>
                media !== null ? showGalleryButtons() : null
            </Card>*/}


        </div>

    )
}

export default ProfileGallery;
