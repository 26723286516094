import {CustomModalContent, MyModal} from "../../Modal/Modal.jsx";
import {useEffect, useMemo, useState} from "react";
import "../Chat.scss"
import {data} from "./stickerData.js"

function StickerModal({onClickCallback, onShowStickerModal = null, visible}) {

    const [isOpen, setIsOpen] = useState(visible);
    const showStickerModal = () =>
    {
        const stickerModals = Array.from(document.getElementsByClassName('chat-main__modal-base'));
        stickerModals.forEach((stickerModal) => {
            stickerModal.style.display = 'block';
        })
        setIsOpen((prev) => !prev);
        if (onShowStickerModal !== null && onShowStickerModal !== undefined) {
            onShowStickerModal();
        }
    }

    useEffect(() => {

    }, []);

    useEffect(() => {
        console.log('visible: ' + visible);
    }, [visible]);

    useEffect(() => {
        console.log('data: ' + JSON.stringify(data));
    }, [data]);

    return (
        <>
        <MyModal key={Math.random()} onOpen={showStickerModal}>
            <div className="chat-main__sticker">
                <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.5"
                          d="M20.0714 13.0097C20.6165 13 21.2494 13 22 13V12C22 7.28596 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28596 2 4.92893 2 3.46447 3.46447C2 4.92893 2 7.28596 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28596 22 12 22H13C13 21.2494 13 20.6165 13.0097 20.0714C13.0292 18.9774 13.0878 18.2372 13.2641 17.6093L13.2667 17.5999C13.2799 17.5534 13.2937 17.5074 13.3082 17.462L13.3146 17.442C13.5336 16.7682 13.859 16.1445 14.2715 15.5903C14.3083 15.5409 14.3458 15.492 14.384 15.4437C15.1608 14.4605 16.2188 13.7121 17.442 13.3146C18.1067 13.0987 18.8788 13.031 20.0714 13.0097Z"
                          fill="#1C274C"/>
                    <path
                        d="M14.8793 21.8034L14.8748 21.8044L14.8138 21.8171L14.8119 21.8175C14.2266 21.9371 13.6206 21.9999 13 21.9999H12L12.344 21.6563C12.7648 21.236 12.9991 20.6661 13.0097 20.0713C13.031 18.8787 13.0987 18.1066 13.3146 17.4419C13.9505 15.4848 15.4849 13.9504 17.442 13.3145C18.1067 13.0986 18.8788 13.0309 20.0714 13.0096C20.6661 12.999 21.236 12.7649 21.6566 12.3443L22 12.001V12.9999C22 13.6619 21.9285 14.3072 21.7929 14.9285C21.0456 18.351 18.3511 21.0456 14.9286 21.7928L14.8793 21.8034Z"
                        fill="#1C274C"/>
                    <path
                        d="M15 12C15.5523 12 16 11.3284 16 10.5C16 9.67157 15.5523 9 15 9C14.4477 9 14 9.67157 14 10.5C14 11.3284 14.4477 12 15 12Z"
                        fill="#1C274C"/>
                    <path
                        d="M9 12C9.55228 12 10 11.3284 10 10.5C10 9.67157 9.55228 9 9 9C8.44772 9 8 9.67157 8 10.5C8 11.3284 8.44772 12 9 12Z"
                        fill="#1C274C"/>
                    <path
                        d="M13.3146 17.442C13.5336 16.7682 13.859 16.1445 14.2715 15.5903C13.6028 16.0111 12.8269 16.25 12 16.25C11.0541 16.25 10.175 15.9374 9.44666 15.3975C9.11389 15.1509 8.64418 15.2207 8.39752 15.5534C8.15086 15.8862 8.22067 16.3559 8.55343 16.6026C9.52585 17.3234 10.7151 17.75 12 17.75C12.4338 17.75 12.8567 17.7014 13.2641 17.6093L13.2667 17.5999C13.2799 17.5534 13.2937 17.5074 13.3082 17.462L13.3146 17.442Z"
                        fill="#1C274C"/>
                </svg>
            </div>
        </MyModal>

    {isOpen && (
        <CustomModalContent
            onClose={
                () => setIsOpen(false)
            }
            className="chat-main__modal-base"
            background="#fff"
        >
                {data.images.map((imageRow, i) => (

                    <div className="chat-main__modal-wrapper">
                {
                    imageRow.map((image, i) => (
                        <img
                        src={image}
                        alt="Veronica"
                        className="chat-main__modal-sticker"
                        onClick={
                            () => {
                                onClickCallback(image);
                                setIsOpen(false);
                            }
                        }
                        />
                    ))
                }
                    </div>
                ))}

        </CustomModalContent>
    )}
        </>
    )
}

export default StickerModal;
