import "./Blog.scss"
import BlogCard from "./BlogCard.jsx";
import React, {useEffect, useState} from "react";
import {fetchPosts} from "../../fetchingAPI/posts.js";

function Blog () {

    const [posts, setPosts] = useState(null);
    const [meta, setMeta] = useState(null);

    const getPosts = async(page) => {
        let result = await fetchPosts(page);
        setPosts(result.posts.data);
        setMeta(result.posts.meta);
    }

    useEffect(() => {
        console.log('posts: ' + JSON.stringify(posts));
    }, [posts]);

    const handleLoadNextPage = (evt) => {
        if (evt !== null)
            evt.preventDefault();
        console.log('handleLoadNextPage');
        console.log('meta: ' + JSON.stringify(meta));
        console.log('users: ' + JSON.stringify(posts));

        const currentPage = meta.current_page;
        const lastPage = meta.last_page;

        console.log('currentPage: ' + currentPage);
        console.log('lastPage: ' + lastPage);

        if (currentPage < lastPage) {
            getPosts(currentPage + 1);
        }
    };

    useEffect(()=> {
        getPosts(0);
    }, []);

    return (
        <div className="blog-main__wrapper">
            <h2 className="blog-main__header">Записи в блоге:</h2>
            <div className="blog-main">
                {
                    posts !== null && posts !== undefined && posts.map((post) => {
                        return (
                            <BlogCard post={post}/>
                        )
                    })
                }
            </div>

            <div className="blog-main__control">
                {
                    meta?.current_page < meta?.last_page ?
                        <button
                            type="button"
                            onClick={handleLoadNextPage}
                            className="profiles-list__btn"
                        >Загрузить еще
                        </button> :
                        <React.Fragment key={Math.random()} />
                }

            </div>
        </div>
    )
}

export default Blog;
