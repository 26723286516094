import PrefEnums from "../PrefEnums.jsx";
import React from "react";

const hobbyFeatures = {
    bike: 'Велосипед',
    cinema: 'Кино',
    games: 'Игры',
    music: 'Музыка',
    self_dev: 'Саморазвитие',
    travel: 'Путешествия',
    yoga: 'Йога',
    hobby_other: 'Другое',
}

function ProfileHobbyCard ({info, handleChange}) {
    return (

        <div className="profile__col-wrapper">

            <div className="profile__subtitle hobby">
                Хобби
            </div>

            <div className="profile__col profile__hobby-col">

                <div className="profile-list__features">
                    <PrefEnums
                        info={info}
                        onChange={handleChange}
                        features={hobbyFeatures}
                    />
                </div>
            </div>

        </div>
    )
}

export default ProfileHobbyCard;
