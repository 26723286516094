import './PrefsSelect.scss';
import { Dropdown } from 'primereact/dropdown';
import {useEffect} from "react";

function getStringifyValue(value) {
    if (typeof value === 'number') {
        if (value === 0) {
            return 'нет';
        }
        if (value === 1) {
            return 'да';
        }
    }

    if (typeof value === 'string') {
        if (value.length === 0) {
            return 'Не указывать'
        } else {
            return value;
        }
    }

    return 'Неизвестное значение';
}

function PrefsSelect({ label, name, prefs, value, onChange }) {

    useEffect(() => {
        //console.log('name: ' +  name + ' value: ' + value);
    }, []);

    useEffect(() => {
    }, [prefs]);

    return (
        <label className="prefs-select">
            <span className="prefs-select__title">{label}:</span>

            {/*<Dropdown value={value} onChange={onChange} options={prefs} optionLabel="name"
                      placeholder="Select a City" className="w-full md:w-14rem" /> */}

            <Dropdown value={value} style={{maxWidth: "118px"}} onChange={(e) => {e.target.name = name; onChange(e)}} options={prefs} optionLabel=""
                      placeholder="..." className="w-full md:w-14rem" />
            {/*
            <select
                className="prefs-select__value"
                name={name}
                value={value}
                onChange={onChange}
            >
                {prefs
                    && prefs.map((value) =>
                        <option
                            key={value}
                            value={value}
                        >{getStringifyValue(value)}</option>)
                }
            </select>
            */}
        </label>
    )
}

export default PrefsSelect;
