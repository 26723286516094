export const data = {
    images: [
        [
            "/storage/stickers/be-mine.png",
            "/storage/stickers/bee.png",
            "/storage/stickers/cassette-tape.png",
            "/storage/stickers/chocolate-box.png",
            "/storage/stickers/coffee-cup.png",
            "/storage/stickers/stamp.png",
        ],
        [
            "/storage/stickers/cupid.png",
            "/storage/stickers/got-you.png",
            "/storage/stickers/i-love-you.png",
            "/storage/stickers/i-love-you(1).png",
            "/storage/stickers/love.png",
            "/storage/stickers/valentines-day.png",
        ],
        [
            "/storage/stickers/love-birds.png",
            "/storage/stickers/love-letter.png",
            "/storage/stickers/love-message.png",
            "/storage/stickers/love-message(1).png",
            "/storage/stickers/love-song.png",
            "/storage/stickers/you-have-the-key.png",
        ],
    ]
};

