import React from "react";


const lookFeatures = {
    piercing: 'Пирсинг',
    scars: 'Шрамы',
    tatoos: 'Татуировки',
    tunnels: 'Туннели',
    look_other: 'Другое',
}

function LookCard ({ info, notFilledShortMessage, Features}) {

    return (
        <div className="profile__col">
            <div className="profile__subtitle">
                Внешность
            </div>
            <ul className="profile__info profile-list">
                <li className="profile-list__item">
                    {/*<ProfileItem
                                                info={info}
                                                name={'body_type'}
                                                title={'Телосложение'}
                                            />*/}
                    Телосложение: {info.body_type === "" ? notFilledShortMessage : info.body_type}
                </li>
                <li className="profile-list__item">
                    Цвет глаз: {info.eye_color === "" ? notFilledShortMessage : info.eye_color}
                </li>
                <li className="profile-list__item">
                    Цвет волос: {info.hair_color === "" ? notFilledShortMessage : info.hair_color}
                </li>
                <li className="profile-list__item">
                    Особенности:
                    <div className="profile-list__features">
                        <Features
                            info={info}
                            features={lookFeatures}
                        />
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default LookCard;