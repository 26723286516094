import "./PremiumCard.scss"
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {useEffect, useRef, useState} from "react";
import {fetchCreatePayment, fetchPaymentCallbackTest} from "../../fetchingAPI/payments.js";
import KassifyPaymentForm from "../Integrations/PaymentsForms/KassifyPaymentForm.jsx";
import TinkoffPaymentForm from "../Integrations/PaymentsForms/TinkoffPaymentForm.jsx";
import {fetchCurrenciesRates} from "../../fetchingAPI/3-party.js";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const featuresList = () => (
    <>
        <li className="premium_modal__li">Сообщение - отправка и чтение.
            Фото и видео во всех разделах.
        </li>

        <li className="premium_modal__li">
            Лайки к фото.
        </li>
        <li className="premium_modal__li">
            Подарки группы А
        </li>
        <li className="premium_modal__li">
            Подарки Группы В (после покупки дополнительного доступа)
        </li>
        <li className="premium_modal__li">
            Видеть все профили полностью.
        </li>

        <li className="premium_modal__li">
            Блокировать пользователя (для себя)
        </li>
    </>
)

function PremiumCard({title, header, background, price, productId}) {

    const [formData, setFormData] = useState({});
    const submitRef = useRef();
    const { isLogIn } = useSelector(state => state.loginReducer);
    const navigate = useNavigate();


    const handleCheckoutClick = async () => {
        if (!isLogIn) {
            navigate('/login');
        } else {
            let result = await fetchCreatePayment({productIds: [productId]});
            console.log('createPayment result: ' + JSON.stringify(result));
            const data = result.payment.form.original.form;

            result = await fetchCurrenciesRates();
            console.log('getCUrreniesRates result: ' + JSON.stringify(result));
            if (result.ok) {
                data.amount = Number((data.amount / result.rates.USD).toFixed(2));
            }
            data.description = title;
            setFormData(data);

            //const result = await fetchPaymentCallbackTest();
            console.log('result: ' + JSON.stringify(result));
        }

    }

    const testPaymentCallback = async(orderId) => {
        const result = await fetchPaymentCallbackTest(orderId);
        console.log('testPaymentCallback result: ' + JSON.stringify(result));
    }

    useEffect(() => {
        console.log('formData: ' + JSON.stringify(formData));
        if (Object.keys(formData).length > 0) {
            //testPaymentCallback(formData?.id);
            submitRef.current.click();

        }
    }, [formData]);


    return (
        <Card style={{width: "30%", background: background}}
              title={title} header={header}>

            {/*<KassifyPaymentForm formData={formData} ref={submitRef} />*/}
            <TinkoffPaymentForm formData={formData} ref={submitRef}/>


            <div className="premium-modal__content">
                <ul>
                    {featuresList()}
                </ul>
                <h2>{price}</h2>
                <Button label="Заказать" style={{width: "200px", height: "50px"}} onClick={handleCheckoutClick}/>
            </div>
        </Card>
    )
}

export default PremiumCard;
