import {createContext} from "react";

export const SubscriptionContext = createContext({
    subscription: null,
    setSubscription: () => {}
});

export const isFeatureExists = (subscription, name) => {
    let result = false;
    subscription?.plan?.features.forEach((feature) => {
        if (feature.name === name) {
            result = true;
            return result;
        }
    });
    return result;
}
