import {FileUpload} from "primereact/fileupload";
import {useEffect, useRef} from "react";
import {X_CSRF_TOKEN} from "../../assets/headers.js";

function ChatFileUploader({onUpload}) {
    const fileUploadUrl = '/upload';
    const fileUploadRef = useRef(null);
    const maxFileSize = 50000000;


    /**
     * Установка X-CSRF-TOKEN в зголовки запроса
     * @param event
     */

    const handleBeforeSend = (event) => {
        console.log('handle before upload');
        console.log('handleBeforeSend: ' + JSON.stringify(event));
        event.xhr.setRequestHeader("X-CSRF-TOKEN", X_CSRF_TOKEN);
    }



    return (
        <FileUpload
            mode="basic" name="file_name" url={fileUploadUrl} accept="image/*, video/*" maxFileSize={maxFileSize}
            onUpload={onUpload} auto chooseLabel=" cc"
            chooseOptions={{icon: "chat-main__upload-icon"}} className="chat-main__attach"
            onBeforeSend={(event) => {
                handleBeforeSend(event);
            }}
            ref={fileUploadRef}
        />
    )
}

export default ChatFileUploader;